import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import ProductCard from "./solutionsData/ProductCard";
import {
  Fibre,
  Telephones,
  Camera,
  Printers,
  WebApp,
  LTE,
  Monitors,
} from "./solutionsData/SolutionsData";
import Policy from "pages/Home/components/Policy/Policy";

const SubNavBar = () => {
  const [filter, setFilter] = useState("lte");
  const [products, setProducts] = useState(Telephones);

  useEffect(() => {
    if (filter == "telephony") {
      setProducts(Telephones);
    }
    if (filter == "fibre") {
      setProducts(Fibre);
    }
    if (filter == "cctv") {
      setProducts(Camera);
    }
    if (filter == "monitor") {
      setProducts(Monitors);
    }
    if (filter == "printer") {
      setProducts(Printers);
    }
    if (filter == "webDev") {
      setProducts(WebApp);
    }
    if (filter == "lte") {
      setProducts(LTE);
    }
  }, [filter]);

  return (
    <section>
      <Container>
        <Row>
          <Col lg="12" className="text-center mb-4">
            <h3 className="menu_title_7">Customize Your Solution</h3>
          </Col>
          <Col lg="12" className="text-center mb-5">
            <button
              className={`filter-btn_7 ${
                filter == "lte" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("lte")}
            >
              LTE
            </button>
            <button
              className={`filter-btn_7 ${
                filter == "fibre" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("fibre")}
            >
              Fibre
            </button>
            <button
              className={`filter-btn_7 ${
                filter == "telephony" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("telephony")}
            >
              Telephony
            </button>
            <button
              className={`filter-btn_7 ${
                filter == "cctv" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("cctv")}
            >
              CCTV
            </button>
            <button
              className={`filter-btn_7 ${
                filter == "monitor" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("monitor")}
            >
              Monitor
            </button>
            <button
              className={`filter-btn_7 ${
                filter == "printer" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("printer")}
            >
              Printers
            </button>
            <button
              className={`filter-btn_7 ${
                filter == "webDev" ? "active_btn_7" : ""
              }`}
              onClick={() => setFilter("webDev")}
            >
              Hosting
            </button>
          </Col>

          {products.map((item) => (
            <Col lg="3" md="4" sm="6" xs="6" className="mb-4" key={item.id}>
              {" "}
              <ProductCard item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default SubNavBar;
