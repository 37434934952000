import React, { useState, useEffect, useContext } from "react";
import { useCart } from "react-use-cart";
import { MainContext } from "contexts/MainContext";
import { useNavigate } from "react-router-dom";

const Checkout = () => {
  const {
    isEmpty,
    totalUniqueItems,
    items,
    totalItems,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  const { cartItems, setcartItems } = useContext(MainContext);
  const { cartGrandTotal, setcartGrandTotal } = useContext(MainContext);
  let navigate = useNavigate();

  //   if (isEmpty) return <h3 className="text-center">Cart is Empty</h3>;

  return (
    <div>
      <div className="py-4" style={{ fontFamily: "Poppins" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <div
                  className="card-header bg-success"
                  style={{ textAlign: "center", paddingTop: "20px" }}
                >
                  <h5
                    className="fw-bold"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    Installation/Shipping Details
                  </h5>
                </div>
                <div className="card-body" style={{ fontFamily: "Poppins" }}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Full Name</label>
                        <input
                          type="text"
                          required
                          name="fullname"
                          className="form-control"
                          placeholder="fullname"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          Company <small>(If applicable)</small>
                        </label>
                        <input
                          type="text"
                          name="company"
                          className="form-control"
                          placeholder="company"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Phone Number</label>
                        <input
                          type="number"
                          required
                          name="phone"
                          className="form-control"
                          placeholder="phonenumber"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Email Address</label>
                        <input
                          type="email"
                          required
                          name="email"
                          className="form-control"
                          placeholder="email"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label>Physical Address</label>
                        <textarea
                          rows="1"
                          name="address"
                          required
                          className="form-control"
                          placeholder="address"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>City</label>
                        <input
                          type="text"
                          required
                          name="city"
                          className="form-control"
                          placeholder="city"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Province</label>
                        <input
                          type="text"
                          name="province"
                          required
                          className="form-control"
                          placeholder="province"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Zip / Postal code</label>
                        <input
                          type="text"
                          name="zipcode"
                          className="form-control"
                          placeholder="zipcode"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>
                          VAT <small>(If applicable)</small>
                        </label>
                        <input
                          type="text"
                          name="vat"
                          className="form-control"
                          placeholder="vat"
                          style={{
                            borderColor: "#4DB849",
                            border: "1px solid #4DB849",
                          }}
                        />
                      </div>
                    </div>

                    {/* PayFast button */}

                    <div className="col-md-12">
                      <div className="form-group text-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ color: "white", fontWeight: "bold" }}
                        >
                          Place Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5" style={{ fontFamily: "Poppins" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th width="50%">Product</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  {cartItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td>R{item.price}</td>
                        <td>({item.quantity})</td>
                        <td>R{item.price * item.quantity}</td>
                      </tr>
                    );
                  })}

                  <tr style={{ fontWeight: "bold" }}>
                    <td colSpan="3" className="text-end fw-bold">
                      Grand Total
                    </td>
                    <td colSpan="2" className="text-end fw-bold">
                      R{cartGrandTotal}
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                style={{
                  color: "#dcdada",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
                className="btn btn-primary m-2"
                onClick={() => navigate("/solutions")}
              >
                Continue Shopping
              </button>
              <br />
              <small>
                By placing an order with I-Net Telecoms, you are agreeing to our{" "}
                <a href="/policy"> Terms & Conditions</a>.{" "}
              </small>
              {/* Your personal information will be processed in accordance with our Privacy Policy. */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
