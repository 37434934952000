import Footer from "components/Footer";
import Header from "components/Header";
import HeaderMini from "components/HeaderMini";
import React from "react";
import Banner from "./components/Banner/index";
import BookAppointment from "./components/BookAppointment";
import Clients from "./components/Clients/Clients";
import Companies from "./components/Companies";
import ContactUs from "./components/ContactUs";
import INetApp from "./components/INetApp/index";
import Services from "./components/Services/index";
import Testimonial from "./components/Testimonials/index.js";
import Welcome from "./components/Welcome";

export default function Home() {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Banner />
      <Welcome />
      <Services />
      <INetApp />
      <Testimonial />
      <Clients />
      <ContactUs />
      {/* <BookAppointment /> */}
    </div>
  );
}
