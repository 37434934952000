import React from "react";
import logo from "assets/images/logo3_dark.png";
import appstore from "assets/images/appstore.png";
import playstore from "assets/images/playstore.png";
import paygate from "assets/images/paygate/PayGate-Brand-Logos.png";
import huawei from "assets/images/huawei.png";
import {
  Grid,
  Box,
  Typography,
  Button,
  Checkbox,
  Radio,
  Slider,
  Switch,
  TextField,
  Link,
  Tabs,
  Paper,
  Card,
  Dialog,
  Avatar,
  Divider,
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  ClickAwayListener,
  Alert,
} from "@material-ui/core";
import TemplateButton from "./TemplateButton";
import SendIcon from "@material-ui/icons/Send";
import image1 from "assets/images/footer/1.svg";
import image2 from "assets/images/footer/2.svg";
import image3 from "assets/images/footer/3.svg";
import image4 from "assets/images/footer/4.svg";
import image5 from "assets/images/footer/5.svg";

export default function Footer() {
  const socialIcons = [
    { name: "Facebook", icon: image1 },
    { name: "Instagram", icon: image2 },
    { name: "Twiter", icon: image3 },
    { name: "Linkedin", icon: image4 },
    { name: "Linkedin", icon: image5 },
  ];

  return (
    <div
      style={{
        padding: "30px 30px 1px 30px",
        backgroundColor: "#181819",
        margin: "0px 0px 0px 0px",
        color: "#ececec",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <div className="footer-wrapper">
        <div className="footer-left-side">
          <img
            style={{
              //  height: 80,
              width: 320,
              left: -30,
              position: "relative",
              marginBottom: 30,
            }}
            src={logo}
          ></img>

          <div
            style={{
              marginBottom: 30,
              fontSize: 15,
              fontWeight: "300",
              paddingRight: 30,
            }}
          >
            Connecting the world through technology<br></br>
            Keeping you at the forefront of technology
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            {socialIcons.map((item, i) => {
              const image_size = 30;
              return (
                <div
                  className="TouchableOpacity"
                  key={i.toString()}
                  style={{
                    width: image_size,
                    height: image_size,
                    //    backgroundColor: 'red',
                    borderRadius: image_size,
                    margin: "0px 10px 0px 0px",
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    src={item.icon}
                  ></img>
                </div>
              );
            })}
          </div>
        </div>

        <div className="footer-right-side">
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <div
              style={{
                fontWeight: "600",
                fontSize: 20,
                marginBottom: 10,
              }}
            >
              Download our i-Net Telecoms App
            </div>
            <div className="footer-stores-wrapper">
              <img
                onClick={(e) => {
                  window.open(
                    "https://apps.apple.com/za/app/i-net-telecoms/id1564894890"
                  );
                }}
                className="footer-appstore store-logo"
                src={appstore}
              ></img>

              <img
                onClick={(e) => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.inettelecoms&gl=ZA"
                  );
                }}
                className="footer-playstore store-logo"
                src={playstore}
              ></img>

              <img
                onClick={(e) => {
                  window.open(
                    "https://appgallery.huawei.com/search/I-Net%20Telecoms"
                  );
                }}
                className="footer-appgallery store-logo"
                src={huawei}
              ></img>
            </div>
          </div>

          <div
            className="footer-subscribe"
            style={{
              paddingTop: 10,
            }}
          >
            <div className="footer-subscribe-title">
              <small> Subscribe to get news on data deals letter</small>
            </div>
            <div
              className="footer-subscribe-inputandbutton-wrapper"
              style={{
                display: "flex",
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                label="Email Address"
                style={{
                  marginRight: 10,
                }}
              />
              <TemplateButton
                style={{
                  color: "black",
                  width: 150,
                  backgroundColor: "#4DB849",
                }}
                variant="contained"
                title="Subscribe"
              />
            </div>
          </div>
        </div>

        <div className="box-container">
          <div className="box">
            <div
              style={{
                fontWeight: "600",
                fontSize: 20,
                marginBottom: 10,
                width: "100vw",
              }}
            >
              Contact Info
            </div>
            <a href="#">
              {" "}
              <i className="fas fa-phone "></i>
              <small> (+27) 021 205 5802</small>
            </a>
            <a href="#">
              {" "}
              <i className="fas fa-envelope"></i>
              <small> info@i-nettelecoms.co.za</small>
            </a>
            <a href="#">
              {" "}
              <i className="fas fa-map-marker-alt"></i>{" "}
              <small>Cape Town, Western Cape, 7925</small>
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom-divider" style={{}}></div>

      <div style={{}} className="payment-gateways-logos">
        <img src={paygate} className="payment-gateway-image" />
      </div>

      <hr color="#4C4C4D" />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 14,
          fontWeight: "300",
          height: 25,
          marginTop: 10,
          gap: 15,
        }}
      >
        Developed By Ncodia <a href="/policy">Terms & Conditions</a>
      </div>
    </div>
  );
}
