import React from "react";
import LeftSide from "./components/LeftSide";
import RightSide from "./components/RightSide/index.js";

export default function Banner() {
  return (
    <div className="banner" style={{}}>
      <div
        className="left-side"
        style={{
          padding: "30px 0px",
        }}
      >
        <LeftSide />
      </div>

      <div
        className="right-side"
        style={{
          backgroundColor: "white",
          //  padding: '30px 0px',
        }}
      >
        <RightSide />
      </div>
    </div>
  );
}
