import logo_import1 from "assets/images/logos/logo1.png";
import logo_import2 from "assets/images/logos/logo2.png";
import logo_import3 from "assets/images/logos/logo3.png";

import logo_import1_dark from "assets/images/logos/logo1_dark.png";
import logo_import2_dark from "assets/images/logos/logo2_dark.png";
import logo_import3_dark from "assets/images/logos/logo3_dark.png";

/* I-Net Logos: START */
export const logo = logo_import1;
export const logo_1 = logo_import1;
export const logo_2 = logo_import2;
export const logo_3 = logo_import3;

export const logo_dark = logo_import1_dark;
export const logo_1_dark = logo_import1_dark;
export const logo_2_dark = logo_import2_dark;
export const logo_3_dark = logo_import3_dark;
/* I-Net Logos: END */
