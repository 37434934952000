import { PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import { MainContext } from "contexts/MainContext";
import { db } from "plugins/firebase";
import { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import PDFQuote from "./components/PDFQuote";
export default function ViewQuote({}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { sendNotifications, handleError } = useContext(MainContext);

  const [quote, setquote] = useState(null);
  const [showLoadingIndicator, setshowLoadingIndicator] = useState(true);

  const getCompanySettings = async () => {
    return new Promise((resolve, reject) => {
      db.get(
        "settings.company",
        (companySettings) => {
          resolve(companySettings);
        },
        (e) => {
          handleError(e, 195);
        }
      );
    });
  };
  const getClientAccount = async (accountId) => {
    return new Promise((resolve, reject) => {
      db.get(
        `accounts.${accountId}`,
        (account) => {
          console.log("kkkkkk", account);
          resolve(account);
        },
        (e) => {
          handleError(e, 195);
        }
      );
    });
  };
  const getQuote = async () => {
    return new Promise((resolve, reject) => {
      db.get(
        "quotes",
        (docs) => {
          if (docs.length == 0) {
            console.log("Quote was not found found!");
            //alert("Invalid Link");
            navigate("/");
          } else {
            var quote = docs[0];
            db.get("settings.quotes", (doc) => {
              var adLink = doc.adLink;
              if (adLink) {
                axios.get(adLink, { responseType: "arraybuffer" }).then((v) => {
                  resolve({
                    ...quote,
                    adLink:
                      "data:image/jpeg;base64," +
                      Buffer.from(v.data).toString("base64"),
                  });
                });
              } else {
                resolve(quote);
              }
            });

            /////////Update Viewed in firebase////////////
            if (!quote.viewed) {
              db.update(
                `quotes.${quote.id}`,
                { viewed: true, viewedAT: new Date() },
                () => {
                  console.log("NEW VIEW");
                  const msg = `${quote.accountName} has opened quote ${quote.quoteNum}`;
                  sendNotifications("quote_opened", msg);
                },
                (e) => {
                  console.log("Error Update Viewed in firebase", e);
                }
              );
            }
          }
        },
        (error) => {
          console.log("Error getting documents:", error);
        },

        { query: `quoteLink == ${id}` }
      );
    });
  };

  useEffect(() => {
    getCompanySettings().then((companySettings) => {
      getQuote().then((quote) => {
        getClientAccount(quote.accountNumber).then((account) => {
          var quote_ = {
            ...quote,
            client: account,
            company: companySettings,
          };
          console.log("eeeeeeeeeeeee", quote_);
          setquote(quote_);
        });
      });
    });

    return () => {};
  }, []);

  const indicator_size = 70;

  return (
    <div style={{ height: "100vh" }}>
      {quote ? (
        <PDFViewer width="100%" height="100%" className="app">
          <PDFQuote
            quote={quote}
            setshowLoadingIndicator={setshowLoadingIndicator}
          />
        </PDFViewer>
      ) : null}
      {showLoadingIndicator ? (
        <div
          style={{
            position: "absolute",
            top: 0,

            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 90,
          }}
        >
          <div>
            <ReactLoading
              type={"spin"}
              color={"green"}
              height={indicator_size}
              width={indicator_size}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
