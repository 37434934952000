import "./App.css";

import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
//import firebase from 'firebase';
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useRoutes,
  Router,
  Outlet,
} from "react-router-dom";
import routes from "../src/routes/routes";

const Routing = () => {
  let routing = useRoutes(routes);
  return routing;
};
console.log("App Version: 20");

function App() {
  /* 
  useEffect(() => {
    initFirebase()
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log('log User is signed in',)
      } else {
        // history.push("/login")
      }
    });
    return () => {
    }
  }, []) */

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </div>
  );
}

export default App;
