import React, { useEffect, useState } from "react";
import Cart from "./cart/Cart";
import { CartProvider } from "react-use-cart";
import SubNavBar from "./solutionsTemplate/SubNavBar";
import { useStateValue } from "./StateProvider";

const Solutions = () => {
  return (
    <CartProvider>
      <SubNavBar />
      <Cart />
    </CartProvider>
  );
};

export default Solutions;
