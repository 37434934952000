import { Divider } from '@material-ui/core'
import React from 'react'

export default function Heading({ title , color}) {
    return (
        <div
        className='heading'
            style={{
      
            }}
        >
            <div
            className='heading-title'
                style={{
  color: color ? color : 'black',
                 
                }}
            >{title}</div>
            
            <div
                style={{
                    backgroundColor: color ? color : 'black',
                    height: 5,
                    width: 50,

                    marginTop: 10,


                }}
            >

            </div>

        </div>
    )
}
