import Footer from "components/Footer";
import Header from "components/Header";
import HeaderMini from "components/HeaderMini";
import Home from "pages/Home/index";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { MainContext } from "contexts/MainContext";
import { db } from "plugins/firebase";

export default function MainLayout({}) {
  const { setnotificationsSettings, handleError } = useContext(MainContext);

  useEffect(() => {
    db.get(
      "settings.notifications.notifications",
      (docs) => {
        var data = docs.map((doc) => {
          var data = doc;
          var id = doc.id;
          return { id, ...data };
        });
        console.log(`xxxxxxxxxxxx`, data);

        setnotificationsSettings(data);
      },
      (e) => {
        handleError(e, 195);
      }
    );
    return () => {};
  }, []);

  return (
    <div className="app">
      <Header></Header>
      <HeaderMini></HeaderMini>
      <div className="header-banner-gap" />
      <Outlet />
      <Footer />
    </div>
  );
}
