import React, {
  Component,
  createContext,
  useState,
  useRef,
  useEffect,
} from "react";
import { db } from "../plugins/firebase";
import Axios from "axios";
import { iNetApi } from "app-config";

const MainContext = createContext();

export default function MainContextProvider(props) {
  //const user = firebase.auth().currentUser
  const [drawerOpen, setdrawerOpen] = React.useState(false);
  const [clients, setclients] = useState([]);
  const [cartItems, setcartItems] = useState([]);
  const [cartGrandTotal, setcartGrandTotal] = useState([]);
  const [openDrawer, setopenDrawer] = useState(false);
  const [notifications, setnotifications] = useState([]);
  const [notificationsSettings, setnotificationsSettings] = useState([]);
  const [users, setusers] = useState([]);
  const [selectedUser, setselectedUser] = useState("");
  //////////////////////////////////
  function handleError(e, errorNum) {
    // toastError(`Ooops an Error: ${errorNum} occured`);
    if (e) {
      console.log(
        `HANDLE ERRORRRRRRRRRRRRRRRRRRRRRRR ${
          " - " + errorNum ? errorNum : null
        }`,
        e
      );
    }
    //stopLoadingIndicator();
    // closeModal();
  }

  //////////////////////////////////

  const sendNotifications = async (ticketIdName, message) => {
    const send = (notificationsSettingsLocal) => {
      var data = notificationsSettingsLocal.find((item, i) => {
        return item.idName == ticketIdName;
      });
      if (!ticketIdName) {
        console.log(
          "PLEASE FIX THIS: NO TICKET ID WAS FOUND FOR A SEND NOTIFICATION FUNCTION CALLED"
        );
        return;
      }

      data.sendTo.map((item, i) => {
        if (item.type == "Email") {
          Axios.post(`${iNetApi}/send-email`, {
            to: item.value,
            subject: message,
            message: message,
          })
            .then((v) => {
              console.log("Resaults:", v);
            })
            .catch((e) => {
              console.log("Error:", e);
            });
        }
        if (item.type == "SMS") {
          Axios.post(`${iNetApi}/send-sms`, {
            phoneNumber: item.value,
            message: message,
          })
            .then((v) => {
              console.log("Resaults:", v);
            })
            .catch((e) => {
              console.log("Error:", e);
            });
        }
        if (item.type == "App") {
          const to = item.value;
          Axios.post(`${iNetApi}/expo-live-notifications`, {
            to,
            title: message,
            body: message,
          })
            .then((v) => {
              console.log("Resaults:", v);
            })
            .catch((e) => {
              console.log("Error:", e);
            });
        }
      });
    };
    if (notificationsSettings.length == 0) {
      db.get(
        "settings.notifications.notifications",
        (data) => {
          console.log(`SENDING NOTIFICATIONS 22222222`);
          send(data);
        },
        (e) => {
          handleError(e, 195);
        }
      );
    } else {
      console.log(`SENDING NOTIFICATIONS 11111111`);
      send(notificationsSettings);
    }
  };
  //////////////////////////////////
  return (
    <MainContext.Provider
      value={{
        cartGrandTotal,
        setcartGrandTotal,
        cartItems,
        setcartItems,
        notificationsSettings,
        setnotificationsSettings,
        handleError,
        sendNotifications,
        drawerOpen,
        setdrawerOpen,
        users,
        setusers,
        setclients,
        clients,
        notifications,
        setnotifications,
        setopenDrawer,
        openDrawer,
        selectedUser,
        setselectedUser,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
}
export { MainContextProvider, MainContext };
