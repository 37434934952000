import Heading from "components/Heading";
import React from "react";
import service1 from "assets/images/services/1.jpg";
import service2 from "assets/images/services/3.jpg";
import service3 from "assets/images/services/5.jpg";
import service4 from "assets/images/services/6.jpg";
import service5 from "assets/images/services/7.jpg";
import service6 from "assets/images/services/9.jpg";

import Layout1 from "./components/Layout1";
import Layout2 from "./components/Layout2";
import TemplateButton from "components/TemplateButton";
import { mdiCarLightDimmed } from "@mdi/js";
export default function Services() {
  const services = [
    {
      title: "Telephones",
      image: service1,
      description:
        "Upgrade your Telephone System today with i-Net Telecoms. Get a top of the range Hosted pbx solution with very competitively low call rates. with our per second billing, cut your monthly call costs by as much as 45% less. No copper lines required.",
      points: [
        { id: "1", description: "Unlimted Extensions" },
        { id: "2", description: "Cheap Call Rates" },
        { id: "3", description: "Free Internal Calls" },
      ],
      number: 1,
    },
    {
      title: "Internet (LTE / Fibre)",
      image: service3,
      description:
        "Experience super fast internet speeds, no line required Installation done within 24hrs UPGRADE to extremely fast internet speeds today.",
      points: [
        { id: "1", description: "Unlimted Band Width" },
        { id: "2", description: "Uncapped Speed" },
        { id: "3", description: "No Contracts" },
      ],
      number: 2,
    },
    {
      title: "Email & Web Hosting",
      image: service2,
      description:
        "Need a reliable email & web hosting company, look no further . With our 24/7 online support we can be sure to keep your domain & emails up & running.",
      points: [
        { id: "1", description: "Bussiness Email" },
        { id: "2", description: "Website Development" },
        { id: "3", description: "24/7 Support" },
      ],
      number: 3,
    },
    {
      title: "Printers & Photocopiers",
      image: service4,
      description:
        "At i-Net Telecoms we have a tailor made solution just for your business. For all your Printing, Faxing, Scanning & photocopying solutions, Get in touch with us today for a free quote. ",
      points: [
        { id: "1", description: "All Paper Sizes" },
        { id: "2", description: "Full Color" },
        { id: "3", description: "24/7 Support" },
      ],
      number: 4,
    },

    {
      title: "CCTV & Alarm Systems",
      image: service5,
      description:
        "See and secure the i-net way, Offsite remote view (access your cameras from anywhere in the world) high quality, clear HD video. When it comes to CCTV & Security, at i-Net Telecoms we don’t compromise. At i-Net Telecoms, Product Quality is a major factor which is why we supply our clients with top of the range quality brands to ensure reliability.",
      points: [
        { id: "1", description: "Night Vision Mode" },
        { id: "2", description: "Upto 1 Month Footage Storage" },
        { id: "3", description: "24/7 Support" },
      ],
      number: 5,
    },
    {
      title: "App Development",
      image: service6,
      description:
        "Need an APP for your business? Look no further ? Need funding? With whatever funds you have available at hand get in touch with us & we will provide you with a custom solution for your business.",
      points: [
        { id: "1", description: "App Design/Coding" },
        { id: "2", description: "App Deployment/Launch" },
        { id: "3", description: "App Hosting" },
      ],
      number: 6,
    },
  ];

  return (
    <div
      style={{
        color: "white",
      }}
    >
      <Heading color="black" title="Our Solutions" />

      <div className="services-mobile-layout sm-services">
        {services.map((item, i) => {
          const image_size = 150;
          return (
            <div
              key={i.toString()}
              style={{
                backgroundColor: i % 2 == 0 ? "#203b3c" : "#26494A",
                paddingTop: 40,
              }}
            >
              <div
                style={{
                  height: image_size,
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: 5,
                }}
              >
                <img
                  style={{
                    width: image_size,
                    height: "80%",

                    borderRadius: 5,
                  }}
                  src={item.image}
                ></img>
              </div>

              <div
                style={{
                  textAlign: "center",

                  fontSize: 20,
                  fontWeight: "bold",
                  marginBottom: 15,
                }}
              >
                {item.title}
              </div>
              <div
                style={{
                  padding: "0px 60px",
                  textAlign: "center",
                  fontSize: 12,

                  maxHeight: 90,
                  overflow: "hidden",
                }}
              >
                {item.description}
              </div>
              <div
                style={{
                  paddingTop: 10,

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 60,
                }}
              >
                <TemplateButton onClick={() => {}} title="Request a quote" />
              </div>
            </div>
          );
        })}
      </div>

      <div className="lg-services">
        <Layout1
          title={services[0].title}
          image={services[0].image}
          description={services[0].description}
          points={services[0].points}
          number={services[0].number}
        />
        <Layout2
          title={services[1].title}
          image={services[1].image}
          description={services[1].description}
          points={services[1].points}
          number={services[1].number}
        />
        <Layout1
          title={services[2].title}
          image={services[2].image}
          description={services[2].description}
          points={services[2].points}
          number={services[2].number}
        />
        <Layout2
          title={services[3].title}
          image={services[3].image}
          description={services[3].description}
          points={services[3].points}
          number={services[3].number}
        />
        <Layout1
          title={services[4].title}
          image={services[4].image}
          description={services[4].description}
          points={services[4].points}
          number={services[4].number}
        />
        <Layout2
          title={services[5].title}
          image={services[5].image}
          description={services[5].description}
          points={services[5].points}
          number={services[5].number}
        />
      </div>
    </div>
  );
}
