import { pdf, PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import { format, isAfter } from "date-fns";
import { db } from "plugins/firebase";
import { formatFirebaseDate } from "plugins/time";
import { toastError } from "plugins/toast";
import { toMoney } from "plugins/valueConvetors";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import PDFStatement from "./components/PDFStatement";

export default function ViewStatement() {
  const { id, blobId } = useParams();
  const [showLoadingIndicator, setshowLoadingIndicator] = useState(true);
  const [invoices, setinvoices] = useState();
  const [payments, setpayments] = useState();
  const [creditNotes, setcreditNotes] = useState();
  const [account, setaccount] = useState();
  const [statement, setstatement] = useState();
  const [adLink, setadLink] = useState("");

  const statementMonthsPeriod = 3;

  //////////////////////////////////////////////
  useEffect(() => {
    db.get(`invoices.statements`, (doc) => {
      if (doc && doc.adLink) {
        var adLink = doc.adLink;
        axios.get(adLink, { responseType: "arraybuffer" }).then((v) => {
          setadLink(
            "data:image/jpeg;base64," + Buffer.from(v.data).toString("base64")
          );
        });
      } else {
        setadLink(null);
      }
    });

    db.get(
      `invoices`,
      (docs) => {
        var data = docs.map((doc) => {
          var id = doc.id;
          var item = doc;
          var dateFormatted = format(item.date.toDate(), "dd/MM/yyyy");
          var dueDateFormatted = format(item.dueDate.toDate(), "dd/MM/yyyy");
          var invoiceNum = id;
          var type = "invoice";
          return {
            id,
            ...item,
            type,
            invoiceNum,
            dateFormatted,
            dueDateFormatted,
          };
        });
        setinvoices(data);
      },
      (error) => {
        alert("Opps an error 9060 occured: Please report to developer.");
        console.log("Error getting documents:", error);
      },
      {
        query: `accountNumber == ${id}`,
      }
    );
    ///////////////////////////////////
    db.get(
      `payments`,
      (docs) => {
        var data = docs.map((doc) => {
          var id = doc.id;
          var item = doc;
          var dateFormatted = format(item.date.toDate(), "dd/MM/yyyy");
          var type = "payment";
          var paymentNum = id;
          return { id, ...item, type, dateFormatted, paymentNum };
        });

        setpayments(data);
      },
      (error) => {
        alert("Opps an error 9061 occured: Please report to developer.");
        console.log("Error getting documents:", error);
      },
      {
        query: `accountNumber == ${id}`,
      }
    );
    ///////////////////////////////////

    db.get(
      `creditNotes`,
      (docs) => {
        var data = docs.map((doc) => {
          var id = doc.id;
          var item = doc;
          var dateFormatted = format(item.date.toDate(), "dd/MM/yyyy");
          var creditNoteNum = id;
          var type = "creditNote";
          return {
            id,
            ...item,
            type,
            creditNoteNum,
            dateFormatted,
          };
        });

        setcreditNotes(data);
      },
      (error) => {
        alert("Opps an error 9062 occured: Please report to developer.");
        console.log("Error getting documents:", error);
      },
      {
        query: `accountNumber == ${id}`,
      }
    );

    ///////////////////////////////////
    db.get(
      `accounts.${id}`,
      (doc) => {
        var id = doc.id;
        var item = doc;
        setaccount({
          id,
          ...item,
        });
      },
      (error) => {
        alert("Opps an error 9062 occured: Please report to developer.");
        console.log("Error getting documents:", error);
      }
    );
    return () => {};
  }, []);
  useEffect(() => {
    if (account && payments && invoices && creditNotes && adLink !== "") {
      getAccountTransactions();
    }

    return () => {};
  }, [account, payments, invoices, creditNotes, adLink]);

  function getAccountTransactions() {
    //Join all payments and invoices to create all transcations
    var all_Transactions = [...invoices, ...payments, ...creditNotes];

    // sort all transactions based on date
    const sorted_transactions = all_Transactions.sort(function (a, b) {
      var aa = formatFirebaseDate(a.date).split("/").reverse().join();
      var bb = formatFirebaseDate(b.date).split("/").reverse().join();
      return aa < bb ? -1 : aa > bb ? 1 : 0;
    });

    // add line totals for statemnts
    var totalPaymentsAndCredits = 0;
    var lineTotal = 0;

    var AllTransactions = sorted_transactions.map((item, i) => {
      var type = item.type;
      var amount = Number(item.amount);

      if (!type) {
        toastError("This error 783. Please contact developers.");
      }

      if (type == "invoice") {
        lineTotal = lineTotal + amount;
      }
      if (type == "payment") {
        lineTotal = lineTotal - amount;
        totalPaymentsAndCredits = totalPaymentsAndCredits + amount;
      }
      if (type == "creditNote") {
        lineTotal = lineTotal - amount;
        totalPaymentsAndCredits = totalPaymentsAndCredits + amount;
      }
      return { ...item, lineTotal: Math.abs(lineTotal) };
    });
    var totalInvoices = 0;
    var totalPayments = 0;
    var totalCretidNotes = 0;

    invoices.sort(function (a, b) {
      var aa = formatFirebaseDate(a.date).split("/").reverse().join(),
        bb = formatFirebaseDate(b.date).split("/").reverse().join();
      return aa < bb ? -1 : aa > bb ? 1 : 0;
    });

    var current = 0;
    var ninteyDays = 0;
    var sixteyDays = 0;
    var thirtyDays = 0;
    var oneHundredAndTwentyDays = 0;

    invoices.map((item, i) => {
      var amount = Number(item.amount);
      totalInvoices = totalInvoices + amount;

      totalPaymentsAndCredits = totalPaymentsAndCredits - amount;

      if (totalPaymentsAndCredits < 0) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const today = new Date();
        const invoiceDate = item.date.toDate();

        const diffDays = Math.round(Math.abs((today - invoiceDate) / oneDay));
        console.log("pppppppp", totalPaymentsAndCredits, diffDays);

        if (diffDays <= 30) {
          console.log("Days Current", diffDays);
          current = current + totalPaymentsAndCredits;
          totalPaymentsAndCredits = 0;
          return;
        }
        if (31 <= diffDays && diffDays <= 60) {
          console.log("Days 30+", diffDays);
          thirtyDays = thirtyDays + totalPaymentsAndCredits;
          totalPaymentsAndCredits = 0;
          return;
        }
        if (61 <= diffDays && diffDays <= 90) {
          console.log("Days 60+", diffDays);
          sixteyDays = sixteyDays + totalPaymentsAndCredits;
          totalPaymentsAndCredits = 0;
          return;
        }
        if (91 <= diffDays && diffDays <= 120) {
          console.log("Days 90+", diffDays);
          ninteyDays = ninteyDays + totalPaymentsAndCredits;
          totalPaymentsAndCredits = 0;
          return;
        }
        if (diffDays >= 121) {
          console.log("Days 120+", diffDays);
          oneHundredAndTwentyDays =
            oneHundredAndTwentyDays + totalPaymentsAndCredits;
          totalPaymentsAndCredits = 0;
          return;
        }
      }

      return;
    });

    payments.map((item, i) => {
      totalPayments = totalPayments + Number(item.amount);
      return;
    });
    creditNotes.map((item, i) => {
      totalCretidNotes = totalCretidNotes + Number(item.amount);
      return;
    });

    var accBalanceLocal = totalInvoices - (totalPayments + totalCretidNotes);

    var today = new Date();
    var statementDate = new Date(
      statementMonthsPeriod ? today.getFullYear() : 2020,
      today.getMonth() - statementMonthsPeriod ? statementMonthsPeriod : 0,
      today.getDate()
    );
    var filtered_transactions = AllTransactions.filter((item) => {
      return isAfter(item.date.toDate(), statementDate);
    });

    var firstItemIndex = AllTransactions.findIndex((item) => {
      return item.id == filtered_transactions[0].id;
    });

    var openingTransaction =
      firstItemIndex == 0
        ? { lineTotal: 0 }
        : AllTransactions[firstItemIndex - 1];
    const statementTransactions = [
      {
        ...openingTransaction,
        type: "opening_balance",
        amount: 0,
        id: "",
        date: statementDate,
        dateFormatted: format(statementDate, "dd/MM/yyyy"),
      },
      ...filtered_transactions,
    ];
    const statement = {
      statementLink: id,
      days: {
        current: toMoney(Math.abs(current)),
        ninteyDays: toMoney(Math.abs(ninteyDays)),
        sixteyDays: toMoney(Math.abs(sixteyDays)),
        thirtyDays: toMoney(Math.abs(thirtyDays)),
        oneHundredAndTwentyDays: toMoney(Math.abs(oneHundredAndTwentyDays)),
      },
      ...account,
      date: new Date(),
      accountBalance:
        accBalanceLocal < 0
          ? `R ${toMoney(accBalanceLocal * -1)} (Credit)`
          : `R ${toMoney(accBalanceLocal)}`,
      previousBal: 0,
      totalPaymentsAmount: totalPayments.toFixed(2),
      totalInvoicesAmount: totalInvoices.toFixed(2),
      startDate: format(statementDate, "dd LLL yyyy"),
      endDate: format(new Date(), "dd LLL yyyy"),
      dateFormatted: format(new Date(), "dd/MM/yyyy"),
      transactions: statementTransactions,
      adLink,
    };
    console.log(`hhhhhhhhhhhhh`, statement, account);
    setstatement(statement);

    if (blobId) {
      pdf(
        <PDFStatement
          statement={statement}
          setshowLoadingIndicator={setshowLoadingIndicator}
        />
      )
        .toBlob()
        .then((v) => {
          var reader = new FileReader();
          reader.readAsDataURL(v);
          reader.onloadend = function () {
            var base64data = reader.result;
            db.get(
              `blobs.${blobId}`,
              { data: base64data },
              () => {
                console.log("Record BLOB Added Succesfully");
              },
              (e) => {
                alert("Error Adding Record: Check your log for more info.");
                console.log("Error Adding Record", e);
              }
            );
          };
        })
        .catch((e) => {
          console.log("Error:", e);
        });
    }
  }

  const indicator_size = 70;

  return (
    <div
      onClick={() => {
        console.log(
          "account",
          account,
          "payments",
          payments,
          "invoices",
          invoices,
          "creditNotes",
          creditNotes
        );
      }}
      style={{ height: "100vh", width: "100vw", overflow: "auto" }}
    >
      {statement ? (
        <PDFViewer width="100%" height="100%" className="app">
          <PDFStatement
            statement={statement}
            setshowLoadingIndicator={setshowLoadingIndicator}
          />
        </PDFViewer>
      ) : null}
      {showLoadingIndicator ? (
        <div
          style={{
            position: "absolute",
            top: 0,

            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 90,
          }}
        >
          <div>
            <ReactLoading
              type={"spin"}
              color={"green"}
              height={indicator_size}
              width={indicator_size}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
