import { Button } from "@material-ui/core";
import React from "react";

export default function TemplateButton({ onClick, title, style, variant }) {
  return (
    <Button
      disableElevation
      variant={variant ? variant : "outlined"}
      color="inherit"
      onClick={
        onClick
          ? onClick
          : () => {
              console.log("No Action Set Yet: Enter Onclick Prop");
            }
      }
      style={style}
    >
      {title ? title : "Title Plc"}
    </Button>
  );
}
