import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./styles/banner.css";
import "./styles/welcome.css";
import "./styles/services.css";
import "./styles/headings.css";
import "./styles/iNetApp.css";
import "./styles/products.css";
import "./styles/testimonials.css";
import "./styles/companies.css";
import "./styles/contactus.css";
import "./styles/bookappointment.css";
import "./styles/footer.css";
import "./styles/product-card.css";
import "./styles/subNavBar.css";
import "./styles/login.css";

import App from "./App";

import reportWebVitals from "./reportWebVitals";
import MainContextProvider from "./contexts/MainContext";
import theme from "./plugins/muiTheme/index";

import { ThemeProvider } from "@material-ui/styles";
window.process = {};
ReactDOM.render(
  <React.StrictMode>
    <MainContextProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MainContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
