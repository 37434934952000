import React from "react";
import format from "date-fns/format";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
import { logo_2 } from "theme/images";
import { toMoney } from "plugins/valueConvetors";
import { text_grey } from "theme/colors";
import {
  calculateInvoiceTotal,
  calculateSubTotal,
  calculateSubtotalLessDiscount,
  calculateTax,
} from "plugins/invoiceCalculations";
import logo_icons from "assets/images/logos/logo_icons.jpg";
import Poppins from "assets/fonts/poppins/Poppins-Regular.ttf";

import Poppins_100 from "assets/fonts/poppins/Poppins-Thin.ttf";
import Poppins_200 from "assets/fonts/poppins/Poppins-ExtraLight.ttf";
import Poppins_300 from "assets/fonts/poppins/Poppins-Light.ttf";
import Poppins_400 from "assets/fonts/poppins/Poppins-Regular.ttf";
import Poppins_500 from "assets/fonts/poppins/Poppins-Medium.ttf";
import Poppins_600 from "assets/fonts/poppins/Poppins-SemiBold.ttf";
import Poppins_700 from "assets/fonts/poppins/Poppins-Bold.ttf";
import Poppins_800 from "assets/fonts/poppins/Poppins-ExtraBold.ttf";
import Poppins_900 from "assets/fonts/poppins/Poppins-Black.ttf";
import PageFooter from "./components/PageFooter";

export default function PDFInvoice({ invoice, setshowLoadingIndicator }) {
  console.log("kkkkkkkkkkkkkkkkkk", invoice);
  // Font.register({ family: "Poppins", src: Poppins });
  Font.register({
    family: "Poppins",
    fonts: [
      { src: Poppins }, // font-style: normal, font-weight: normal
      {
        src: Poppins_100,
        fontWeight: 100,
      },
      {
        src: Poppins_200,
        fontWeight: 200,
      },
      {
        src: Poppins_300,
        fontWeight: 300,
      },
      {
        src: Poppins_400,
        fontWeight: 400,
      },
      {
        src: Poppins_500,
        fontWeight: 500,
      },
      {
        src: Poppins_600,
        fontWeight: 600,
      },
      {
        src: Poppins_700,
        fontWeight: 700,
      },
      {
        src: Poppins_800,
        fontWeight: 800,
      },
      {
        src: Poppins_900,
        fontWeight: 900,
      },
    ],
  });
  const columns = [
    {
      id: "item",
      title: "Description",
      width: "60%",
      backgroundColor: "red",
    },
    {
      id: "qty",
      title: "Qty",
      width: "10%",
      backgroundColor: "green",
    },
    {
      id: "unitPrice",
      title: "Price ",
      width: "15%",
      backgroundColor: "red",
    },
    {
      id: "total",
      title: "Total",
      width: "15%",
      backgroundColor: "green",
    },
  ];
  const bankingDetails = [
    { title: "Bank Name", value: "Absa" },
    { title: "Account Name", value: "i NET TELECOMS (Pty) LTD" },
    { title: "Account Number", value: "4097804035" },
    { title: "Branch Code", value: "632005" },
    { title: "Branch Name", value: "Santyger" },
  ];
  const invoiceDetails = [
    { name: "Invoice #", value: invoice.id },
    {
      name: "Invoice Date",
      value: format(invoice.date.toDate(), "dd/MM/yyyy"),
    },
    {
      name: "Due Date",
      value: format(invoice.dueDate.toDate(), "dd/MM/yyyy"),
    },
  ];
  const iNetContactInfo = [
    { name: "Phone", value: "021 205 5802" },
    { name: "Email", value: "billing@i-nettelecoms.co.za" },
    { name: "VAT", value: "4760296709" },
    { name: "REG", value: "2019/562715/07" },
  ];
  const clientContactInfo = [
    {
      name: "Phone",
      value: invoice.client.contact && invoice.client.contact.phone,
    },
    {
      name: "Email",
      value: invoice.client.contact && invoice.client.contact.email,
    },
    { name: "VAT", value: invoice.client.vatNum && invoice.client.vatNum },
    { name: "ACC", value: invoice.accountNumber },
  ];
  const totals = [
    { name: "Subtotal", value: `R ${calculateSubTotal(invoice)}` },
    { name: "Discount", value: `R ${invoice.discountAmount}` },
    {
      name: "Subtotal Less Discount",
      value: `R ${calculateSubtotalLessDiscount(invoice)}`,
    },
    { name: "Tax (15%)", value: `R ${calculateTax(invoice)}` },
    { name: "Total", value: `R ${calculateInvoiceTotal(invoice)}` },
  ];

  const themeColor = "#4DB849";
  const border_color = "#565656";
  const border_width = 0.3;

  return (
    <Document
      onRender={() => {
        setshowLoadingIndicator(false);
      }}
      style={{}}
    >
      <Page
        size="A4"
        style={{
          paddingBottom: invoice.adLink ? 175 : 60,

          fontFamily: "Poppins",
        }}
      >
        <View
          style={{
            //   backgroundColor: "red",

            display: "flex",
            flexDirection: "column",
            paddingLeft: 50,
            paddingRight: 50,
          }}
        >
          {/* Invoice header ALL*/}
          <View
            fixed
            style={{
              marginTop: 50,
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* Invoice header left */}
            <View
              style={{
                width: "50%",
                alignItems: "flex-start",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Image
                style={{
                  //  width: "50%",
                  left: -10,
                  height: 50,
                }}
                /*  src={{
                          uri:
                            "https://firebasestorage.googleapis.com/v0/b/i-net-app.appspot.com/o/assets%2Flogo2.png?alt=media&token=4df15f5a-3b4a-4f02-be51-ed0034fc13ed",
                        }} */
                src={logo_2}
              ></Image>
              <Image
                style={{
                  height: 30,
                  left: 30,
                }}
                src={logo_icons}
              ></Image>
            </View>
            {/* Invoice header Right */}
            <View
              style={{
                width: "50%",

                fontSize: 30,
                fontWeight: "bold",
              }}
            >
              <Text
                style={{
                  paddingBottom: 5,
                  color: themeColor,
                  textAlign: "right",
                  fontWeight: 600,
                  fontSize: 17,
                }}
              >
                Tax Invoice
              </Text>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",

                  margin: "10px 0px 10px 0px",
                  fontSize: 15,
                }}
              >
                {invoiceDetails.map((item, i) => {
                  return (
                    <View
                      key={i.toString()}
                      style={{
                        display: "flex",
                        flexDirection: "row",

                        justifyContent: "spaceBetween",
                        width: "75%",
                        marginBottom: 7,
                      }}
                    >
                      <View
                        style={{
                          width: 70,
                          left: 30,
                        }}
                      >
                        <Text
                          style={{
                            color: "#818181",
                            fontWeight: 500,
                            fontSize: 8.5,
                          }}
                        >
                          {item.name}
                        </Text>
                      </View>

                      <View
                        style={{
                          width: 115,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 600,
                            fontSize: 8.5,
                          }}
                        >
                          {item.value}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
          {/* Invoice Details ALL*/}
          <View
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 25,
            }}
          >
            {/* Invoice Details lEFT*/}

            <View
              style={{
                width: "49%",
                padding: "15px 0px 16px 7px",
                backgroundColor: "#F4F8F9",
                borderRadius: 5,
              }}
            >
              <Text style={styles.detailsHeading}>Billed by</Text>
              <View style={styles.detailsContainer}>
                <Text
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {invoice.company.name}
                </Text>
                <Text style={{ fontSize: 9, fontWeight: 300 }}>
                  {invoice.company.contact.address.street},{" "}
                  {invoice.company.contact.address.area}
                </Text>

                <Text style={{ fontSize: 9, fontWeight: 300 }}>
                  {invoice.company.contact.address.city},{" "}
                  {invoice.company.contact.address.region}
                </Text>

                <View style={{ display: "flex", marginTop: 7 }}>
                  {iNetContactInfo.map((item, i) => {
                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 5,
                        }}
                      >
                        <View style={{}}>
                          <Text
                            style={{
                              display: "flex",
                              fontSize: 8.5,
                              width: 50,
                              fontWeight: 500,
                            }}
                          >
                            {item.name}
                          </Text>
                        </View>
                        <View style={{}}>
                          <Text
                            style={{
                              fontWeight: 300,

                              fontSize: 8.5,
                              width: "70%",
                            }}
                          >
                            {item.value}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
            {/* Invoice Details RIGHT*/}

            <View
              style={{
                backgroundColor: "#F4F8F9",
                borderRadius: 5,
                width: "49%",
                padding: "15px 0px 16px 7px",

                display: "flex",
                flexDirection: "column",
                marginLeft: 0,
              }}
            >
              <Text style={styles.detailsHeading}>Billed to</Text>
              <View style={styles.detailsContainer}>
                <Text
                  style={{
                    fontWeight: 600,
                    marginBottom: 5,

                    fontSize: 14,
                  }}
                >
                  {invoice.accountName}
                </Text>
                <Text style={{ fontSize: 9, marginBottom: 2, fontWeight: 300 }}>
                  {invoice.client.contact.address.street},{" "}
                  {invoice.client.contact.address.area}
                </Text>

                <Text style={{ fontSize: 9, fontWeight: 300 }}>
                  {invoice.client.contact.address.city},{" "}
                  {invoice.client.contact.address.region}
                </Text>
                <View
                  style={{
                    fontSize: 16,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    {clientContactInfo.map((item, i) => {
                      return (
                        <View
                          key={i.toString()}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 5,
                          }}
                        >
                          <View style={{}}>
                            <Text
                              style={{
                                display: "flex",
                                fontSize: 8.5,
                                width: 50,
                                fontWeight: 500,
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                          <View style={{}}>
                            <Text
                              style={{
                                fontSize: 8.5,
                                fontWeight: 300,

                                width: "70%",
                              }}
                            >
                              {item.value}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Table */}
          <View
            style={{
              marginBottom: 10,
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            {/*     Table header */}
            <View
              style={{
                width: "100%",
                backgroundColor: themeColor,
                display: "flex",
                flexDirection: "row",
                borderWidth: border_width,
                borderColor: border_color,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              {columns.map((column, i) => {
                return (
                  <View
                    style={{
                      width: column.width,
                      padding: 7,
                      //borderRightWidth: i != 3 ? border_width : 0,
                      // borderColor: border_color,
                      textAlign:
                        column.id == "qty" || column.id == "unitPrice"
                          ? "center"
                          : column.id == "total"
                          ? "right"
                          : "left",
                    }}
                    key={i.toString()}
                  >
                    <Text style={{ fontSize: 9, color: "white" }}>
                      {column.title}
                    </Text>
                  </View>
                );
              })}
            </View>
            {/*     Table Body */}

            <View
              style={{
                width: "100%",
                backgroundColor: "#F4F8F9",

                display: "flex",
                flexDirection: "column",
              }}
            >
              {invoice.items.map((invoiceItem, index) => {
                return (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottomWidth:
                        index != invoice.items.length - 1 ? border_width : 0,
                      borderColor: "#C1C1C1",
                    }}
                    key={index.toString()}
                  >
                    {columns.map((column, i) => {
                      return (
                        <View
                          style={{
                            width: column.width,
                            padding: 5,
                            borderRightWidth: i != 3 ? border_width : 0,
                            borderColor: border_color,
                            height: 40,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                          key={i.toString()}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              fontWeight: 500,
                              textAlign:
                                column.id == "qty" || column.id == "unitPrice"
                                  ? "center"
                                  : column.id == "total"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            {column.id == "total"
                              ? `R ${toMoney(
                                  invoiceItem.qty * invoiceItem.unitPrice
                                )}`
                              : column.id == "unitPrice"
                              ? `R ${toMoney(invoiceItem[column.id])}`
                              : invoiceItem[column.id]}
                          </Text>
                          {i == 0 ? (
                            <Text
                              style={{
                                fontSize: 7,
                                fontWeight: 300,
                                marginTop: 2,
                                textAlign: column.textAlign,
                              }}
                            >
                              {invoiceItem["description"]}
                            </Text>
                          ) : null}
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </View>

          {/* table footer */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {/* table footer LEFT: BANKING DETAILS */}

            {/* table footer RIGHT: SUBTOTALS */}
            <View
              style={{
                width: "50%",
                paddingLeft: 40,
                marginTop: 5,
              }}
            >
              {totals.map((item, i) => {
                if (!invoice.discountAmount && item.name == "Discount") return;
                if (
                  !invoice.discountAmount &&
                  item.name == "Subtotal Less Discount"
                )
                  return;
                const getDiscountTitle = () => {
                  return `Discount (${Math.round(
                    (Number(invoice.discountAmount) /
                      calculateSubTotal(invoice)) *
                      100
                  )}%)`;
                };
                const getTaxTitle = () => {
                  if (invoice.isTaxExclusive) return "Tax (0%)";
                  else return item.name;
                };

                return (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: i != 4 ? 12 : 0,
                      borderTopWidth: i == 4 ? 0.3 : 0,
                      borderColor: border_color,

                      paddingTop: i == 4 ? 10 : 0,
                    }}
                    key={i.toString()}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontWeight: i == 4 ? 600 : 400,
                          fontSize: i == 4 ? 15 : 10,
                        }}
                      >
                        {item.name == "Discount"
                          ? getDiscountTitle()
                          : item.name == "Tax (15%)"
                          ? getTaxTitle()
                          : item.name}
                      </Text>
                    </View>
                    <View style={{ width: 100 }}>
                      <Text
                        style={{
                          fontWeight: i == 4 ? 600 : 400,
                          fontSize: i == 4 ? 15 : 10,
                          textAlign: "right",

                          paddingRight: 5,
                        }}
                      >
                        {item.value}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
          <View
            wrap={false}
            style={{
              width: "100%",
              paddingLeft: 5,
            }}
          >
            <View wrap={false}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  color: themeColor,
                  marginBottom: 10,
                  marginTop: 30,
                }}
              >
                Bank & Payment Details
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 0,
                }}
              >
                {bankingDetails.map((item, i) => {
                  return (
                    <View
                      style={{
                        width: "100%",
                        display: "flex",

                        flexDirection: "row",
                      }}
                      key={i.toString()}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: 8,
                          color: "#202124",
                          fontWeight: "300",
                        }}
                      >
                        {item.title}
                      </Text>
                      <Text
                        style={{ width: "60%", fontSize: 8, fontWeight: 500 }}
                      >
                        {item.value}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{
                marginTop: 30,
                paddingRight: 10,
                width: "45vw",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  color: themeColor,
                  marginBottom: 10,
                }}
              >
                Terms and Conditions
              </Text>
              <Text style={{ fontSize: 8 }}>
                1. Please Use YOUR COMPANY NAME as REFERENCE & send POP to
                billing@i-nettelecoms.co.za
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginTop: 8,
                  fontWeight: 500,
                }}
              >
                Thank you for your support!
              </Text>
            </View>
          </View>
        </View>
        {/* PAGE FOOTER */}
        <PageFooter
          adLink={invoice.adLink}
          border_color={border_color}
          border_width={border_width}
        />
      </Page>
    </Document>
  );
}
const themeColor = "#4DB849";

const styles = StyleSheet.create({
  detailsDeepinfoText1: {
    marginTop: 12,
    display: "flex",
  },

  detailsHeading: {
    color: themeColor,
    fontSize: 10,
    fontWeight: 500,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },

  detailsContainer: {
    paddingLeft: 10,
    marginTop: 10,
  },

  invoice: {
    flexDirection: "column",
    display: "flex",
    marginRight: 30,
  },

  invoiceDate: {
    display: "flex",
  },

  invoiceDateText: {
    marginRight: 10,
  },

  invoiceDateValue: {
    display: "flex",
  },

  dueDate: {
    display: "flex",
  },

  dueDateText: {
    marginRight: 10,
  },

  dueDateValue: {
    display: "flex",
  },

  tablePadding: {
    padding: "1rem 3rem 0px 3rem",
  },

  tableHeader2: {
    color: "white",
    height: 30,
  },

  tableHeaderColumn: {
    padding: 0,
    margin: 0,

    flex: 1,
  },
  // description: {
  //     minWidth: 500,
  //  },
  qty: {
    width: 80,
  },
  price: {
    width: 150,
  },
  total: {
    width: 150,
  },
  tableHeaderColumnFix: {
    paddingLeft: 10,
  },

  tableHeaderTextWrapper: {
    height: 50,

    backgroundColor: themeColor,
    display: "flex",
    alignItems: "center",
    borderRight: "1px solid rgb(183, 184, 184)",
  },

  borderRight: {
    borderRight: "1px solid rgb(183, 184, 184)",
  },
  tableHeaderText: {
    width: "100%",
  },

  centerText: {
    textAlign: "center",
  },

  rightText: {
    textAlign: "right",
    paddingRight: 15,
  },

  rightText2: {
    textAlign: "right",
    paddingRight: 15,
  },

  tableColumnDivider: {
    margin: 0,
    padding: 0,
    borderRight: "1px solid rgba(182, 183, 183, 0,99)",
  },

  totalsFooter: {
    fontSize: 17,
  },

  totalsWrapper: {
    paddingLeft: 30,
  },

  totals: {
    padding: "0rem 3rem 0px 3rem",
    marginTop: -5,
  },

  subTotal: {
    fontWeight: 500,
    fontSize: 18,
  },

  totalsHeading: {
    paddingLeft: 18,
    fontWeight: "normal",
    height: 30,
  },

  totalsHeading2: {
    paddingLeft: 18,
    fontWeight: "normal",
    height: 50,
  },

  totalsHeadingBg: {
    height: 40,
    display: "flex",
    alignItems: "center",
    fontSize: 22,
    fontWeight: 500,
  },

  totalsValue: {
    fontSize: 25,
    fontWeight: 700,
  },

  notes: {
    padding: "5rem 3rem 0px 3rem",
  },

  footerWidth: {
    width: 652,
  },

  removeBorder: {
    borderTop: "2px solid white",
  },

  tableDataWrapper: {
    padding: "0px 15px",
    backgroundColor: "rgb(244, 248, 249)",
    height: 70,
    display: "flex",
    alignItems: "center",
  },

  centerAlign: {
    justifyContent: "center",
  },

  tableDataWrapper2: {
    height: 70,
    backgroundColor: "rgb(244, 248, 249)",
    display: "flex",
    justifyContent: " flex-end",
  },

  tableData: {
    fontWeight: 500,
  },

  margin0: {
    margin: 0,
    padding: 0,
  },

  tableData2: {
    padding: "10px 15px 10px 0px",
    textAlign: "right",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
  },

  bodyWrapper: {
    fontFamily: "Poppins,Arial,Helvetica,sansSerif",
    margin: "auto",
    marginTop: 120,
    marginBottom: 120,
    boxShadow: "0px 0px 8px rgba(0,0,0,0,5)",
    width: 1000,
  },

  table2: {
    borderCollapse: "collapse",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderRadius: 5,
    overflow: "hidden",
    width: "100%",
  },

  tableHeader: {
    //  backgroundColor: #743DDB ,
    color: "white",
  },

  footer: {
    marginTop: 50,
  },

  footerBold: {
    fontWeight: 500,
  },

  footerHeading: {
    color: themeColor,
    fontSize: 19,
    fontWeight: 600,
    marginBottom: 15,
  },

  footerHeadingGap: {
    marginTop: 60,
  },

  bankDetails: {
    display: "flex",
    marginBottom: 5,
  },

  bankDetailsLabel: {
    color: "#818181",
    width: "40%",
  },

  footerWrapper: {
    paddingRight: 30,
  },

  realFooter: {
    marginTop: 60,
  },

  viewInvoiceText: {
    marginTop: 30,
    marginBottom: 7,
  },
  descriptionText: {
    fontSize: 12,
    marginTop: 1,
  },
  powerText: {
    fontSize: 15,
    marginTop: 0,
  },
});
