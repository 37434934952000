import React from 'react'
import dots from 'assets/images/dots2.png'
export default function Heading2({ title, description }) {
    const size= 50

    return (
        <div
        className='heading2'>
            <div
                style={{
                    height: '100%',
                    width: size,
                    marginRight: 15,

                }}
            >
                <div
                    style={{
                        backgroundImage: `url(${dots})`,
                        backgroundSize: '100%',
                        height: size,
                        width: size,
                    }}
                >

                </div>
            </div>

          

 
  
         <div
                style={{

                }}
            >
                 <div className='heading2-title'  >
                {title}
                </div>
                {description ? <p
                    style={{
                        marginTop: 10,
                        paddingRight: '10%',
                        fontSize: 15,
                        fontWeight: 400,
                        lineHeight: 1.4,



                    }}
                >{description} </p> : <p
                    style={{
                        marginTop: 10,
                        paddingRight: '10%',
                        fontSize: 15,
                        fontWeight: 400,
                        lineHeight: 1.4,



                    }}
                >


                    At i-Net Telecoms we have a unique set of value that seperate us from other companies in the telecomunications industry.
                    We have continously made our clients happy with our amazing core principals like stated below.
                    All our clients love us because we stay put to our word and follow through therefore allowing you to be more competitve in your bussiness.


                </p>    }

                

            </div>
        

        

     
       
   </div>

    )
}
