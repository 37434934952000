import { MainContext } from "contexts/MainContext";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import logo from "assets/images/Logo.jpg";

const Cart = () => {
  const {
    isEmpty,
    totalUniqueItems,
    items,
    totalItems,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  let navigate = useNavigate();

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var showdate = new Date();
  var displayTodaysdate =
    showdate.getDate() +
    "/" +
    month[showdate.getMonth()] +
    "/" +
    showdate.getFullYear();

  const { cartItems, setcartItems } = useContext(MainContext);
  const { cartGrandTotal, setcartGrandTotal } = useContext(MainContext);

  useEffect(() => {
    setcartItems(items);
    setcartGrandTotal(cartTotal);
    return () => {};
  }, [items, cartTotal]);

  if (isEmpty) return <h3 className="text-center">Your Cart is Empty</h3>;

  return (
    <section
      className="p-4 container"
      style={{
        borderColor: "#4CB848",
        border: "2px solid #4CB848",
        borderRadius: "5px",
        marginTop: "32px",
        width: "65%",
        fontFamily: "Poppins",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "30px" }}
      >
        <div style={{ width: "50%" }}>
          <img src={logo} style={{ width: "150px" }} alt="" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            width: "50%",
            fontWeight: "bold",
          }}
        >
          Date:{" "}
          <input
            type="text"
            value={displayTodaysdate}
            readOnly="true"
            style={{ border: "none", fontFamily: "Poppins", fontSize: "15px" }}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <h5 style={{ marginLeft: "10px", fontSize: "15px" }}>
            Cart ({totalUniqueItems}) total Items: ({totalItems})
          </h5>

          <table
            className="table table-light table-hover m-0"
            style={{ fontSize: "15px" }}
          >
            <thead>
              <tr>
                <th width="40%">Product</th>
                {/* <th width="30%">Description</th> */}
                <th width="25%">Price(monthly)</th>
                <th width="15%">Qty</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td>                  
                      <img src={item.imgUrl} style={{ height: "6rem" }} />
                    </td> */}
                    <td style={{ fontWeight: "bold" }}>{item.title}</td>
                    {/* <td style={{ fontSize: "10px" }}>
                      {" "}
                      {item.des} {item.des1} {item.des2} {item.des3} {item.des4}{" "}
                      {item.des5} {item.des6}
                    </td> */}
                    <td>R{item.price}</td>
                    <td>({item.quantity})</td>
                    <td style={{display:'flex', gap:'5px'}}>
                      <button
                        className="btn btn-info"
                        onClick={() =>
                          updateItemQuantity(item.id, item.quantity - 1)
                        }
                      >
                        -
                      </button>
                      <button
                        className="btn btn-info "
                        onClick={() =>
                          updateItemQuantity(item.id, item.quantity + 1)
                        }
                      >
                        +
                      </button>
                      {/* <button
                        className="btn btn-danger ms-2"
                        onClick={() => removeItem(item.id)}
                      >
                        Remove Item
                      </button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="col-auto ms-auto" style={{ marginTop: "25px" }}>
          <h5>subtotal: <b>R{cartTotal}</b></h5>
        </div>

        <div className="col-auto ms-auto" style={{ marginTop: "12px" }}>
          <button
            className="btn btn-success m-2"
            onClick={() => navigate("/checkout")}
          >
            Checkout
          </button>
        </div>
      </div>
    </section>
  );
};

export default Cart;
