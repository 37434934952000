import React from "react";
import service1 from "assets/images/services/2.jpg";
import TemplateButton from "components/TemplateButton";
import Content from "./Content";

export default function Layout2({ title, image, points, description, number }) {
  return (
    <div
      style={{
        display: "flex",
        height: 400,
      }}
    >
      <div
        style={{
          width: "50%",
          backgroundColor: "#245054",
          marginLeft: 30,
        }}
      >
        <div
          style={{
            //  backgroundColor: "red",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              position: "relative",
              marginTop: 30,
              width: 50,
              height: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //  color: 'black',
              backgroundColor: "#60D252",
              fontSize: 15,
            }}
          >
            {number}
          </div>
        </div>
        <div
          style={{
            padding: 30,
            paddingRight: 80,
            position: "relative",
            top: -75,
          }}
        >
          <Content title={title} points={points} description={description} />
        </div>
      </div>

      <div
        style={{
          width: "50%",
          marginRight: 30,

          overflow: "hidden",
        }}
      >
        <img
          style={{
            width: "100%",
            minHeight: "100%",
          }}
          src={image}
        ></img>
      </div>
    </div>
  );
}
