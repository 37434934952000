import React, { useContext } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
import Poppins from "assets/fonts/poppins/Poppins-Regular.ttf";
import Poppins_100 from "assets/fonts/poppins/Poppins-Thin.ttf";
import Poppins_200 from "assets/fonts/poppins/Poppins-ExtraLight.ttf";
import Poppins_300 from "assets/fonts/poppins/Poppins-Light.ttf";
import Poppins_400 from "assets/fonts/poppins/Poppins-Regular.ttf";
import Poppins_500 from "assets/fonts/poppins/Poppins-Medium.ttf";
import Poppins_600 from "assets/fonts/poppins/Poppins-SemiBold.ttf";
import Poppins_700 from "assets/fonts/poppins/Poppins-Bold.ttf";
import Poppins_800 from "assets/fonts/poppins/Poppins-ExtraBold.ttf";
import Poppins_900 from "assets/fonts/poppins/Poppins-Black.ttf";
import { toMoney } from "plugins/valueConvetors";
import CoverPage from "./components/CoverPage";
import CostBreakdown from "./components/CostBreakdown";

export default function ProposalPDF({ proposal }) {
  Font.register({
    family: "Poppins",
    fonts: [
      { src: Poppins }, // font-style: normal, font-weight: normal
      {
        src: Poppins_100,
        fontWeight: 100,
      },
      {
        src: Poppins_200,
        fontWeight: 200,
      },
      {
        src: Poppins_300,
        fontWeight: 300,
      },
      {
        src: Poppins_400,
        fontWeight: 400,
      },
      {
        src: Poppins_500,
        fontWeight: 500,
      },
      {
        src: Poppins_600,
        fontWeight: 600,
      },
      {
        src: Poppins_700,
        fontWeight: 700,
      },
      {
        src: Poppins_800,
        fontWeight: 800,
      },
      {
        src: Poppins_900,
        fontWeight: 900,
      },
    ],
  });
  const columns = [
    {
      id: "name",
      title: "Description",
      width: "100%",
      backgroundColor: "red",
    },
  ];

  const themeColor = "#4DB849";
  const border_color = "#DEE2E6";
  const border_width = 0.3;

  const getEquipment = (data) => {
    var rowsAdd = 4 - data.length;
    for (let index = 0; index < rowsAdd; index++) {
      data.push({ name: "" });
    }
    return data;
  };
  return (
    <Document
      onRender={() => {
        // setshowLoadingIndicator(false);
      }}
      style={{}}
    >
      <CoverPage proposal={proposal} />
      {proposal.proposal.map((proposalItem, i) => {
        console.log("sss", proposalItem);

        return (
          <Page
            key={i.toString()}
            size="A4"
            style={{
              fontFamily: "Poppins",
            }}
          >
            <View
              style={{
                paddingBottom: 60,
                height: 550,
                width: 597,
                backgroundColor: "#29C9C7",
              }}
            >
              <Image
                style={{
                  minHeight: 550,
                  height: 550,
                }}
                src={{ uri: proposalItem.image }}
              ></Image>
            </View>
            <View style={{ padding: "0px 30px" }}>
              {/* Table start */}
              <View>
                <Text
                  style={{
                    fontSize: 13,
                    padding: 5,
                    textTransform: "uppercase",
                    paddingTop: 15,
                    paddingBottom: 5,
                  }}
                >
                  Proposed Solution
                </Text>
                {/*     Table header */}
                <View
                  style={{
                    width: "100%",
                    backgroundColor: themeColor,
                    display: "flex",
                    flexDirection: "row",
                    borderWidth: border_width,
                    borderColor: border_color,
                  }}
                >
                  {columns.map((column, i) => {
                    return (
                      <View
                        style={{
                          width: column.width,
                          padding: 7,

                          borderRight: `${
                            i != columns.length - 1 ? border_width + "px" : 0
                          } solid ${border_color}`,
                        }}
                        key={i.toString()}
                      >
                        <Text
                          style={{
                            fontSize: 9,
                            color: "white",
                            fontWeight: "700",
                          }}
                        >
                          {column.title}
                        </Text>
                      </View>
                    );
                  })}
                </View>
                {/*     Table Body */}

                <View
                  style={{
                    width: "100%",
                    backgroundColor: "#F4F8F9",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {getEquipment(proposalItem.equipment).map(
                    (equipmentItem, index) => {
                      return (
                        <View
                          key={index.toString()}
                          wrap={false}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            borderBottomWidth:
                              index != proposalItem.equipment.length - 1
                                ? border_width
                                : 0,
                            borderColor: "#1E1E1E",
                          }}
                        >
                          {columns.map((column, i) => {
                            return (
                              <View
                                key={i.toString()}
                                style={{
                                  flexDirection: "row",
                                  width: column.width,
                                  height: 30,
                                }}
                              >
                                <View
                                  style={{
                                    width: "100%",
                                    padding: 5,
                                    height: "100%",
                                    // borderBottom: `${border_width}px solid ${border_color}`,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    paddingLeft:
                                      column.id == "description" ? 13 : 0,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 9,
                                      fontWeight: 400,
                                      paddingLeft: 15,
                                    }}
                                  >
                                    {column.id == "name"
                                      ? equipmentItem["name"]
                                      : null}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    width: border_width,
                                    height: "100%",
                                    backgroundColor: border_color,
                                  }}
                                ></View>
                              </View>
                            );
                          })}
                        </View>
                      );
                    }
                  )}
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 13,
                  margin: `15px 0px 0px 0px`,
                }}
              >
                <View
                  style={{
                    width: "30%",

                    borderTopWidth: border_width,
                    borderLeftWidth: border_width,

                    borderRightWidth: border_width,
                  }}
                >
                  <Text
                    style={{
                      padding: "10px 10px",
                      borderTopWidth: border_width,
                      borderBottomWidth: border_width,
                      borderLeftWidth: border_width,
                    }}
                  >
                    Once Off Payment
                  </Text>
                  <Text
                    style={{
                      padding: "10px 10px",
                      borderBottomWidth: border_width,
                    }}
                  >
                    Monthly Payment
                  </Text>
                </View>
                <View
                  style={{
                    borderRightWidth: border_width,
                    width: "30%",
                  }}
                >
                  <Text
                    style={{
                      padding: "10px 30px",
                      borderBottomWidth: border_width,
                      borderTopWidth: border_width,
                    }}
                  >
                    {proposalItem.onceOffPayment
                      ? `R ${toMoney(proposalItem.onceOffPayment)}`
                      : "N/A"}
                  </Text>
                  <Text
                    style={{
                      padding: "10px 30px",
                      borderBottomWidth: border_width,
                    }}
                  >
                    {proposalItem.monthlyPayments
                      ? `R ${toMoney(proposalItem.monthlyPayments)}`
                      : "N/A"}
                  </Text>
                </View>
                <View
                  fixed
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",

                    alignItems: "flex-end",
                    paddingLeft: 130,
                  }}
                >
                  <Text
                    fixed
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      fontWeight: 400,

                      color: "black",
                    }}
                    render={({ pageNumber, totalPages }) => {
                      return totalPages > 1
                        ? `Page ${pageNumber} of ${totalPages}`
                        : null;
                    }}
                  ></Text>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
      <CostBreakdown proposal={proposal} />
    </Document>
  );
}
