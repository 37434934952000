import { format } from "date-fns";

export const getDateTime = () => {
  var date = new Date();
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();
  date = mm + "/" + dd + "/" + yyyy;

  return `${date}`;
};

export const getMilliseconds = () => {
  return "nothing";
};

export const formatFirebaseDate = (date, encoding) => {
  try {
    return format(date.toDate(), encoding ? encoding : "dd/MM/yyyy");
  } catch (error) {
    // console.log("ggg", error);
    return "";
  }
};
