import React, { useState, useEffect } from "react";
import logo from "assets/images/Logo.jpg";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { Link } from "react-router-dom";
import { Popover, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AuthModal from "./AuthModal";
import { RiTruckLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Home from "pages/Home";
import ContactUs from "pages/Home/components/ContactUs";
import Solutions from "pages/Solutions/Solutions";
import { AiFillHeart } from "react-icons/ai";
import { icons } from "react-icons";

export default function Header() {
  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));

  const [filter, setFilter] = useState("");
  const [products, setProducts] = useState("");

  useEffect(() => {
    if (filter == "home") {
      setProducts(Home);
    }
    if (filter == "solutions") {
      setProducts(Solutions);
    }
    if (filter == "contact") {
      setProducts(ContactUs);
    }
    if (filter == "account") {
      setProducts(ContactUs);
    }
    if (filter == "trackorder") {
      setProducts();
    }
    if (filter == "account") {
      setProducts();
    }
  }, [filter]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [openAuthModal, setopenAuthModal] = React.useState(false);

  const handleClickOpen = () => {
    setAnchorEl(null);
    setopenAuthModal(true);
  };
  function closePopover() {
    setAnchorEl(null);
  }
  let navigate = useNavigate();

  return (
    <nav className="navbar">
      <div style={{ width: "25%" }} className="navbar_logo">
        <img src={logo} style={{ width: "100%" }} />
      </div>

      <div className="links">
        <Link
          to="/"
          className={`filter-btn_7 ${filter == "home" ? "active_btn_7" : ""}`}
          onClick={() => setFilter("home")}
        >
          Home
        </Link>

        {/* <Link to="/about">About</Link> */}
        <Link
          to="/solutions"
          className={`filter-btn_7 ${
            filter == "solutions" ? "active_btn_7" : ""
          }`}
          onClick={() => setFilter("solutions")}
        >
          Solutions
        </Link>

        {/* <Link to="/projects">Our Team</Link> */}
        <Link
          to="/contact"
          className={`filter-btn_7 ${
            filter == "contact" ? "active_btn_7" : ""
          }`}
          onClick={() => setFilter("contact")}
        >
          Contact
        </Link>

        <Link
          to="/"
          className={`filter-btn_7 ${
            filter == "trackorder" ? "active_btn_7" : ""
          }`}
          onClick={() => setFilter("trackorder")}
        >
          <RiTruckLine /> TrackOrder
        </Link>

        <Link
          to=""
          // onClick={(event) => {
          //   setAnchorEl(event.currentTarget);
          // }}

          className={`filter-btn_7 ${
            filter == "account" ? "active_btn_7" : ""
          }`}
          //    onClick={() => setFilter("account")}
          //   onClick={handleClickOpen}
        >
          <AccountCircleOutlinedIcon />
          My Account
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#26494A",
                padding: 20,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                style={{
                  marginBottom: 10,
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log("log");
                }}
                style={{ color: "black", fontWeight: "bold" }}
              >
                Create Account
              </Button>
            </div>
          </Popover>
          <AuthModal
            openAuthModal={openAuthModal}
            setopenAuthModal={setopenAuthModal}
          />
        </Link>

        {/* <Link to=''>
          <a href=""><AiFillHeart onClick={() => navigate("")}/></a>
        
        </Link> */}

        {/* <Link
          to=''
        >
          <a href="#">
            <AddShoppingCartIcon onClick={() => navigate("")} />
          </a>
        </Link> */}
      </div>
    </nav>
  );
}
