import React, { useState, useContext, useRef, useEffect } from "react";
import SlickSlider from "react-slick";
// import { Zoom } from "react-slideshow-image";
import image1 from "assets/images/screenshots/app_images/App1.jpeg";
import image2 from "assets/images/screenshots/app_images/App2.jpeg";
import image3 from "assets/images/screenshots/app_images/App3.jpeg";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function Slider() {
  // const AppPics = [image1,image2,image3,image4];

  const [slideIndex, setslideIndex] = useState(0);
  const [updateCount, setupdateCount] = useState(0);

  const slider = useRef();

  const settings = {
    pagination: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => setupdateCount(updateCount + 1),
    beforeChange: (current, next) => setslideIndex(next),
  };

  const pagination = [
    {
      name: "Telephones",
      src: image2,
      onClick: () => {
        slider.current.slickGoTo(0);
      },
    },
    {
      name: "CCTV",
      src: image1,
      onClick: () => {
        slider.current.slickGoTo(1);
      },
    },
    {
      name: "Hosting",
      src: image3,
      onClick: () => {
        slider.current.slickGoTo(2);
      },
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div className="i-net-app-slider" style={{}}>
        <div
          onClick={(e) => {
            slider.current.slickGoTo(slideIndex - 1);
          }}
          className="TouchableOpacity i-net-app-slider-button"
          style={{}}
        >
          <ArrowBackIosIcon
            style={{
              position: "relative",
              left: 5,
              color: "white",
              fontSize: 20,
            }}
          />
        </div>
        <div
          onClick={(e) => {
            slider.current.slickGoTo(slideIndex + 1);
          }}
          className="TouchableOpacity i-net-app-slider-button"
          style={{}}
        >
          <ArrowForwardIosIcon
            style={{
              color: "white",
              fontSize: 20,
            }}
          />
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: 'blue',
          width: "50%",
          zIndex: 10,
          marginTop: 320,
          color: "white",
        }}
      >
        {/*      <h3
                    style={{
                        fontWeight: 'bold',

                    }}
                >{pagination[slideIndex].name}</h3> */}
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: 'blue',
          width: "50%",
          zIndex: 10,
          marginTop: 400,
        }}
      ></div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 600,
        }}
      >
        <div
          style={{
            width: 250,

            //  height: '100%',
          }}
        >
          <SlickSlider
            ref={(ref) => {
              slider.current = ref;
            }}
            {...settings}
          >
            {pagination.map((item, i) => {
              return (
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  src={item.src}
                />
              );
            })}
          </SlickSlider>
        </div>
      </div>
    </div>
  );
}
