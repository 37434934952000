export const groupBy = (array, key) =>
  array.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    []
  );
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isEven = (number) => {
  if (!number) throw new Error("No number passed in 'isEven' Function");

  if (number % 2 == 0) {
    return true;
  } else {
    return false;
  }
};
