import MainLayout from "layouts/MainLayout";
import Error404 from "pages/Error404";
import Home from "pages/Home";
import ContactUs from "pages/Home/components/ContactUs";
import Policy from "pages/Home/components/Policy/Policy";
import Checkout from "pages/Solutions/checkout/Checkout";
import Cart from "pages/Solutions/cart/Cart.js";
import React from "react";

import Solutions from "pages/Solutions/Solutions";
import ViewInvoice from "screens/Documents/ViewInvoice";
import ViewStatement from "screens/Documents/ViewStatement";
import ViewQuote from "screens/Documents/ViewQuote";
import ViewProposal from "screens/Documents/ViewProposal";
import DocumentsLayout from "layouts/DocumentsLayout";
const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/solutions", element: <Solutions /> },
      { path: "/checkout", element: <Checkout /> },
      { path: "/contact", element: <ContactUs /> },
      { path: "/policy", element: <Policy /> },
      { path: "/cart", element: <Cart /> },
    ],
  },
  { path: "/invoice/:id", element: <ViewInvoice /> },
  { path: "/invoice/:id/:blobId", element: <ViewInvoice /> },
  { path: "/statement/:id/:blobId", element: <ViewStatement /> },
  { path: "/statement/:id", element: <ViewStatement /> },
  { path: "/quote/:id", element: <ViewQuote /> },
  { path: "/proposal/:proposalLink", element: <ViewProposal /> },
  {
    path: "documents",
    element: <DocumentsLayout />,
    children: [
      { path: "/invoices/:id", element: <ViewInvoice /> },
      { path: "/invoices/:id/:blobId", element: <ViewInvoice /> },
      { path: "/statements/:id/:blobId", element: <ViewStatement /> },
      { path: "/statements/:id", element: <ViewStatement /> },
      { path: "/quotes/:id", element: <ViewQuote /> },
      { path: "/proposals/:proposalLink", element: <ViewProposal /> },
    ],
  },

  {
    path: "*",
    element: <Error404 />,
  },
];

export default routes;
