import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
import { logo_2 } from "theme/images";
import logo_icons from "assets/images/logos/logo_icons.jpg";
import { formatFirebaseDate } from "plugins/time";
import { format } from "date-fns";
import app_gallery from "assets/images/app_stores_logos/app_gallery.png";
import app_store from "assets/images/app_stores_logos/app_store.png";
import play_store from "assets/images/app_stores_logos/play_store.png";
export default function CoverPage({ proposal }) {
  const preparedBy = [
    { name: "Phone", value: proposal.preparedBy.phone },
    { name: "Email", value: proposal.preparedBy.email },
  ];
  const clientContactInfo = [
    { name: "Phone", value: proposal.client.phone },
    { name: "Email", value: proposal.client.email },
  ];

  const appStoresLogos = [
    {
      logo: app_store,
      link: "https://apps.apple.com/za/app/i-net-telecoms/id1564894890",
    },
    {
      logo: play_store,
      link: "https://play.google.com/store/apps/details?id=com.inettelecoms&gl=ZA",
    },
    {
      logo: app_gallery,
      link: "https://appgallery.huawei.com/app/C105302627",
    },
  ];
  ///////////////////////////////////////////////
  const border_color = "#565656";
  const border_width = 0.3;
  return (
    <Page
      size="A4"
      style={{
        fontFamily: "Poppins",
      }}
    >
      <View
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#32A852",
          display: "flex",
        }}
      >
        {/* LOGO && Heading Section */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* LOGO Area */}
          <View
            style={{
              alignItems: "flex-start",
              flexDirection: "column",
              justifyContent: "flex-end",
              display: "flex",
              marginRight: 60,
              backgroundColor: "white",
              padding: 10,
            }}
          >
            <Image
              style={{
                //  width: "50%",
                left: -10,
                height: 70,
              }}
              src={logo_2}
            ></Image>
            <Image
              style={{
                height: 50,
                left: 30,
              }}
              src={logo_icons}
            ></Image>
          </View>
          {/* Heading Area */}
          <View
            style={{
              fontSize: 40,
              fontWeight: "bold",
              marginTop: 15,
              color: "white",
            }}
          >
            <Text style={{}}>Business</Text>
            <Text style={{}}>Proposal</Text>
          </View>
        </View>
        {/* Date Section */}
        <View style={{ padding: "0px 15px", marginBottom: 10 }}>
          <Text
            style={{
              fontSize: 25,
              fontWeight: "bold",
              marginTop: 20,
            }}
          >
            {format(proposal.date.toDate(), "MMMM dd yyyy")}
          </Text>
        </View>
        {/* Prepared By section */}
        <View style={{}}>
          <View
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            {/* Invoice Details lEFT*/}

            <View
              style={{
                width: "49%",
                padding: "15px 0px 0px 7px",
                borderRadius: 5,
              }}
            >
              <Text style={styles.detailsHeading}>Prepared by :</Text>
              <View style={styles.detailsContainer}>
                <Text
                  style={{
                    marginTop: 5,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {proposal.preparedBy.name}
                </Text>

                <View style={{ display: "flex", marginTop: 7 }}>
                  {preparedBy.map((item, i) => {
                    return (
                      <View
                        key={i.toString()}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 5,
                        }}
                      >
                        <View style={{}}>
                          <Text
                            style={{
                              display: "flex",
                              fontSize: 8.5,
                              width: 50,
                              fontWeight: 500,
                            }}
                          >
                            {item.name}
                          </Text>
                        </View>
                        <View style={{}}>
                          <Text
                            style={{
                              fontWeight: 300,

                              fontSize: 8.5,
                              width: "70%",
                            }}
                          >
                            {item.value}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
            {/* Invoice Details RIGHT*/}

            <View
              style={{
                //  backgroundColor: "#F4F8F9",
                borderRadius: 5,
                width: "49%",
                padding: "15px 0px 0px 7px",

                display: "flex",
                flexDirection: "column",
                marginLeft: 0,
                color: "white",
              }}
            >
              <Text style={styles.detailsHeading}>Prepared For :</Text>
              <View style={styles.detailsContainer}>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  {proposal.client.name}
                </Text>

                <View
                  style={{
                    fontSize: 16,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    {clientContactInfo.map((item, i) => {
                      return (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 5,
                          }}
                        >
                          <View style={{}}>
                            <Text
                              style={{
                                display: "flex",
                                fontSize: 8.5,
                                width: 50,
                                fontWeight: 500,
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                          <View style={{}}>
                            <Text
                              style={{
                                fontSize: 8.5,
                                fontWeight: 300,

                                width: "70%",
                              }}
                            >
                              {item.value}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/* Cover Image Section */}
        <View style={{ backgroundColor: "#29C9C7", height: 350 }}>
          <Image
            src={{
              uri: proposal.coverImage,
            }}
            style={{ height: 350 }}
          ></Image>
        </View>
        {/* Footer Section */}
        <View
          style={{
            padding: 15,
            color: "white",
            fontSize: 17,
            fontWeight: 300,
            lineHeight: 1.4,
          }}
        >
          <Text style={{}}>i-Net Telecoms (PTY) Ltd.</Text>
          <Text style={{}}>Unit F6, 1 Prestidge Drive, Sunrise Park,</Text>
          <Text style={{}}>Ndabeni, Cape Town, South Africa</Text>
          <Text>
            <Link style={{}}>www.i-nettelecoms.co.za</Link> | 021-205-5802
          </Text>

          <View
            style={{
              height: 35,
              left: 15,
              width: "100vw",
              position: "absolute",
              bottom: -30,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginRight: 30,
                }}
              >
                {appStoresLogos.map((item, i) => {
                  return (
                    <Link key={i.toString()} src={item.link}>
                      <Image
                        style={{ width: 85, marginLeft: 10, borderRadius: 5 }}
                        source={item.logo}
                      />
                    </Link>
                  );
                })}
              </View>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Download Our Mobile App !
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}
const styles = StyleSheet.create({
  detailsHeading: {
    fontSize: 10,
    fontWeight: 500,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },
  detailsContainer: {
    paddingLeft: 10,
    marginTop: 10,
  },
});
