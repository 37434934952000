import TemplateButton from "components/TemplateButton";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function LeftSide() {
  let navigate = useNavigate();
  return (
    <div
      style={{
        textAlign: "left",
        paddingTop: 30,
        margin: "0px 30px",
        height: 520,
      }}
    >
      <div className="banner-left-side-title">
        Home & Office Automation {/* <br/> */} Solutions
      </div>
      <p className="banner-left-side-description">
        At i-Net Telecoms, we pride ourselves in our work. We're a
        telecommunications passion driven company that provides cutting edge
        office & home automation solutions putting our clients need first.
      </p>
      <div
        style={{
          marginTop: 20,
          display: "flex",
        }}
      >
        <div
          style={
            {
              //  color: 'green'
            }
          }
        >
          <TemplateButton
            // variant='contained'
            title="Book A Sales Presentation"
            style={{
              marginRight: 10,

              //     backgroundColor: 'red',
            }}
          />
        </div>
        <div
          style={
            {
              //    color:'green'
            }
          }
        >
          <TemplateButton
            variant="contained"
            title="Contact Us"
            style={{
              backgroundColor: "#11c3552e",
            }}
            onClick={() => navigate("/contact")}
          />
        </div>
      </div>
    </div>
  );
}
