import React from "react";
import {
  ClientSection,
  ClientColumn,
  ClientText,
  ClientTitle,
  ClientRow,
  ClientWrapper,
  ClientImage,
  ClientTextWrapper,
  Container,
} from "./ClientsStyles.js";
import { clientsData } from "./ClientsData";
import img1 from "assets/images/companies/1.png";
import img2 from "assets/images/companies/2.png";
import img3 from "assets/images/companies/3.png";
import img4 from "assets/images/companies/4.png";
import img5 from "assets/images/companies/5.png";

const Clients = () => {
  return (
    <ClientSection id="clients">
      <Container>
        <ClientTextWrapper>
          <ClientTitle>Our Clients</ClientTitle>
          <ClientText>
            At i-Net Telecoms we have a unique set of value that seperate us
            from other companies in the telecomunications industry. We have
            continously made our clients happy with our amazing core principals
            like stated below. All our clients love us because we stay put to
            our word and follow through therefore allowing you to be more
            competitve in your bussiness.
          </ClientText>
        </ClientTextWrapper>
        <ClientRow>
          {clientsData.map((clients, clientsIndex) => (
            <ClientColumn key={clientsIndex}>
              {clients.map((el, index) => {
                const getImage = (i) => {
                  if (i == 1) return img1;
                  if (i == 2) return img2;
                  if (i == 3) return img3;
                  if (i == 4) return img4;
                  if (i == 5) return img5;
                };
                return (
                  <ClientWrapper key={index}>
                    <ClientImage src={getImage(el.name)} />
                  </ClientWrapper>
                );
              })}
            </ClientColumn>
          ))}
        </ClientRow>
      </Container>
    </ClientSection>
  );
};

export default Clients;
