import { Page, Text, View, Link, Image } from "@react-pdf/renderer";
import { toMoney } from "plugins/valueConvetors";
import Table from "./Table";
import app_gallery from "assets/images/app_stores_logos/app_gallery.png";
import app_store from "assets/images/app_stores_logos/app_store.png";
import play_store from "assets/images/app_stores_logos/play_store.png";
export default function CostBreakdown({ proposal }) {
  console.log("ffffffffffff", proposal);

  const data = [];
  var onceOffTotal = 0;
  var monthlyTotal = 0;

  proposal.proposal.map((item, i) => {
    var onceOffPayment = item.onceOffPayment;
    var monthlyPayments = item.monthlyPayments;
    if (!onceOffPayment || !monthlyPayments) return;
    onceOffTotal = onceOffTotal + Number(onceOffPayment);
    monthlyTotal = monthlyTotal + Number(monthlyPayments);

    data.push({
      description: item.name,
      onceOffPayment: `R${toMoney(onceOffPayment)}`,
      monthlyPayments: `R${toMoney(monthlyPayments)}`,
    });
    item.equipment.map((item, i) => {
      if (item.name.replace(/\s/g, ""))
        data.push({
          description: item.name,
          style: {
            fontSize: 7,
            fontWeight: 300,
          },
        });
    });
  });

  const columns = [
    {
      Header: "Description",
      accessor: "description",
      width: 400,
      HeaderStyle: {},
      CellStyle: {
        fontWeight: 500,
      },
      //  Cell: ({ value }) => {
      //    return formatFirebaseDate(value, "do LLL yyyy");
      //  },
    },
    {
      Header: "Once Off",
      accessor: "onceOffPayment",
      //  Cell: ({ value }) => {
      //    return formatFirebaseDate(value, "do LLL yyyy");
      //  },
    },
    {
      Header: "Monthy",
      accessor: "monthlyPayments",
      //  Cell: ({ value }) => {
      //    return formatFirebaseDate(value, "do LLL yyyy");
      //  },
    },
  ];
  const bankingDetails = [
    { title: "Bank Name", value: "Absa" },
    { title: "Account Name", value: "i NET TELECOMS (Pty) LTD" },
    { title: "Account Number", value: "4097804035" },
    { title: "Branch Code", value: "632005" },
    { title: "Branch Name", value: "Santyger" },
  ];
  const border_color = "#565656";
  const border_width = 0.3;
  const themeColor = "#4DB849";
  const appStoresLogos = [
    {
      logo: app_store,
      link: "https://apps.apple.com/za/app/i-net-telecoms/id1564894890",
    },
    {
      logo: play_store,
      link:
        "https://play.google.com/store/apps/details?id=com.inettelecoms&gl=ZA",
    },
    {
      logo: app_gallery,
      link: "https://appgallery.huawei.com/app/C105302627",
    },
  ];
  return (
    <Page
      size="A4"
      style={{
        fontFamily: "Poppins",
      }}
    >
      <View
        style={{
          paddingBottom: 60,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
            paddingTop: 30,
          }}
        >
          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
            Total Cost Breakdown
          </Text>
        </View>
        <View
          style={{ paddingHorizontal: 30, paddingBottom: 60, paddingTop: 30 }}
        >
          <Table columns={columns} data={data} />
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#F4F8F9",
              borderBottomRightRadius: 2,
              borderBottomLeftRadius: 2,
            }}
          >
            <View
              style={{
                width: 400,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                fontWeight: 600,
                fontSize: 10,
                padding: 5,
                paddingRight: 30,

                borderWidth: border_width,
                borderColor: border_color,
              }}
            >
              <Text style={{}}>Totals</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontWeight: 600,
                fontSize: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
                borderWidth: border_width,
                borderColor: border_color,
              }}
            >
              <Text>R{toMoney(onceOffTotal)}</Text>
            </View>
            <View
              style={{
                flex: 1,
                fontSize: 10,
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
                borderWidth: border_width,
                borderColor: border_color,
              }}
            >
              <Text>R{toMoney(monthlyTotal)}</Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              display: "flex",
              paddingTop: 30,
            }}
          >
            <View
              style={{
                width: 400,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{ fontSize: 15, fontWeight: "bold", paddingRight: 15 }}
              >
                Total Amount Payable
              </Text>
            </View>

            <Text style={{ fontSize: 15, fontWeight: "bold", paddingLeft: 5 }}>
              R {toMoney(onceOffTotal + monthlyTotal)}
            </Text>
          </View>
          <View
            wrap={false}
            style={{
              width: "100%",
              paddingLeft: 5,
            }}
          >
            <View wrap={false}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  color: themeColor,
                  marginBottom: 10,
                  marginTop: 30,
                }}
              >
                Bank & Payment Details
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 0,
                }}
              >
                {bankingDetails.map((item, i) => {
                  return (
                    <View
                      style={{
                        width: "100%",
                        display: "flex",

                        flexDirection: "row",
                      }}
                      key={i.toString()}
                    >
                      <Text
                        style={{
                          width: "40%",
                          fontSize: 8,
                          color: "#202124",

                          fontWeight: "300",
                        }}
                      >
                        {item.title}
                      </Text>
                      <Text
                        style={{ width: "60%", fontSize: 8, fontWeight: 500 }}
                      >
                        {item.value}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{
                marginTop: 10,
                paddingRight: 10,
                width: "45vw",
              }}
            >
              <Text style={{ fontSize: 8 }}>
                1. Please Use YOUR COMPANY NAME as REFERENCE & send POP to
                billing@i-nettelecoms.co.za
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  marginTop: 8,
                  fontWeight: 500,
                }}
              >
                Thank you for your support!
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          height: 35,
          left: 15,
          width: "100vw",
          position: "absolute",
          bottom: 0,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginRight: 30,
            }}
          >
            {appStoresLogos.map((item, i) => {
              return (
                <Link key={i.toString()} src={item.link}>
                  <Image
                    style={{ width: 85, marginLeft: 10, borderRadius: 5 }}
                    source={item.logo}
                  />
                </Link>
              );
            })}
          </View>
          <Text
            style={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            Download Our Mobile App !
          </Text>
        </View>
      </View>
    </Page>
  );
}
