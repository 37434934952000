export const primary_color = '#87CE34'
export const secondary_color = '#F1F5F8'
export const bg_color_primary = '#3C4652'
export const bg_color_secondary = '#D9DDE9'
export const text_color_primary = '#BDBDBD'
export const text_color_secondary = 'rgba(233, 240, 233, 1)'

export const success = 'rgb(40, 167, 69)'
export const info = 'rgb(23, 162, 184)'
export const warning = 'rgb(255, 193, 7)'
export const danger = 'rgb(220, 53, 69)'

export const themeDark = {
    bg_color_primary: 'rgba(23, 201, 117, 1)',
    primary_color: 'rgba(126, 98, 202, 1)',
    secondary_color: 'rgba( 39, 39, 39, 1)',
    text_color_primary: 'rgba( 207, 207, 207, 1)'
}