import React from "react";
import Internet from "assets/images/services/5.jpg";
import Telephony from "assets/images/services/1.jpg";
import CCTV from "assets/images/shop/19.jpg";
import { ClientTitle } from "../Clients/ClientsStyles";
import Print from "assets/images/services/6.jpg";

// import Swiper core and required module
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";
import Heading from "components/Heading";

const data = [
  {
    avatar: Internet,
    name: "Tony Snowden ~ Themofuse Pipelines Director",
    review:
      "I would highly recommend i-Net Telecoms to anyone! We’ve had their telephone system installed now for a year now and the whole set up was trouble free. The support they’ve shown over the year with any queires has been fantastic.",
  },

  // {
  //   avatar: Telephony,
  //   name: "Walter",
  //   review:
  //     "I would highly recommend i-Net Telecoms to anyone! We’ve had their telephone system installed now for a year now and the whole set up was trouble free. The support they’ve shown over the year with any queires has been fantastic.",
  // },
  // {
  //   avatar: CCTV,
  //   name: "Arthur",
  //   review:
  //     "I would highly recommend i-Net Telecoms to anyone! We’ve had their telephone system installed now for a year now and the whole set up was trouble free. The support they’ve shown over the year with any queires has been fantastic.",
  // },
  // {
  //   avatar: Print,
  //   name: "Samantha",
  //   review:
  //     "I would highly recommend i-Net Telecoms to anyone! We’ve had their telephone system installed now for a year now and the whole set up was trouble free. The support they’ve shown over the year with any queires has been fantastic.",
  // },
];

export default function Testimonial() {
  return (
    <>
      <Heading color="black" title="What Clients Say" />
      <section id="testimonials" className="testimonials">
        <div className="test-container">
          {data.map(({ avatar, name, review }, index) => {
            return (
              <div key={index} className="testimonial">
                <div className="test-client-avatar">
                  <img src={avatar} width="100px" height="100%" />
                </div>
                <h5 className="test-client-name">{name}</h5>
                <small className="test-client-review">{review}</small>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
