import React, { useState, useContext } from "react";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/images/Logo.jpg";
export default function HeaderMini() {
  const [openNav, setopenNav] = useState(false);
  const navigate = useNavigate();
  const onClick = (path) => {
    setopenNav(false);
    navigate(path);
    document.body.style.overflow = "visible";
  };
  return (
    <div
      className="sm-header"
      style={{
        //position: "fixed",

        width: "100%",
        zIndex: 30,
        //height: "100%",
      }}
    >
      <div
        style={{
          padding: "0px 20px 0px 20px",

          width: "100%",

          display: "flex",
          justifyContent: "space-between",
          height: 80,
          // backgroundColor: "rgba(239,239,239,0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              //height: 75,
              width: 170,
            }}
            src={logo}
          ></img>
        </div>

        <div
          onClick={() => {
            setopenNav(!openNav);

            if (!openNav) document.body.style.overflow = "hidden";
            else {
              document.body.style.overflow = "visible";
            }
          }}
          style={{
            //margin: "0px 30px",

            display: "flex",
            width: 50,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {openNav ? <CloseIcon /> : <MenuIcon />}
        </div>
      </div>
      {openNav ? (
        <div
          style={{
            backgroundColor: "black",
            position: "fixed",
            width: "100%",
            zIndex: 20,
            display: "flex",
            flexDirection: "column",
            height: 400,
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: 80,
            padding: "30px 0px 30px 0px",
            fontSize: 20,
            fontWeight: "700",
            color: "white",
          }}
        >
          <div
            onClick={() => {
              onClick("/");
            }}
            to="/"
          >
            Home
          </div>
          <div
            onClick={() => {
              onClick("/solutions");
            }}
            to="/"
          >
            Solutions
          </div>
          <div
            onClick={() => {
              onClick("/contact");
            }}
            to="/contact"
          >
            Contact
          </div>
          <div
            onClick={() => {
              onClick("/");
            }}
            to="/"
          >
            Track Order
          </div>
          <div
            onClick={() => {
              onClick("/");
            }}
            to="/"
          >
            My Account
          </div>
        </div>
      ) : null}
    </div>
  );
}
