import { pdf, PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import { MainContext } from "contexts/MainContext";
import { db } from "plugins/firebase";
import { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import PDFInvoice from "./components/PDFInvoice";
export default function ViewInvoice({}) {
  const navigate = useNavigate();
  const { id, blobId } = useParams();
  const { sendNotifications, handleError } = useContext(MainContext);
  const [invoice, setinvoice] = useState(null);
  const [showLoadingIndicator, setshowLoadingIndicator] = useState(true);

  const getCompanySettings = async () => {
    return new Promise((resolve, reject) => {
      db.get(
        "settings.company",
        (companySettings) => {
          resolve(companySettings);
        },
        (e) => {
          handleError(e, 195);
        }
      );
    });
  };
  const getClientAccount = async (accountId) => {
    return new Promise((resolve, reject) => {
      db.get(
        `accounts.${accountId}`,
        (account) => {
          console.log("kkkkkk", account);
          resolve(account);
        },
        (e) => {
          handleError(e, 195);
        }
      );
    });
  };
  const getInvoice = async () => {
    return new Promise((resolve, reject) => {
      db.get(
        `invoices`,
        (docs) => {
          if (docs.length == 0) {
            console.log("Invoice was not found found!");
            // alert("Invalid Invoice Link");
            navigate("/");
          } else {
            var invoice = docs[0];
            db.get("settings.invoices", (doc) => {
              var adLink = doc.adLink;
              if (adLink) {
                axios.get(adLink, { responseType: "arraybuffer" }).then((v) => {
                  resolve({
                    ...invoice,
                    adLink:
                      "data:image/jpeg;base64," +
                      Buffer.from(v.data).toString("base64"),
                  });
                });
              } else {
                resolve(invoice);
              }
            });

            if (blobId) {
              pdf(
                <PDFInvoice
                  invoice={invoice}
                  setshowLoadingIndicator={setshowLoadingIndicator}
                />
              )
                .toBlob()
                .then((v) => {
                  var reader = new FileReader();
                  reader.readAsDataURL(v);
                  reader.onloadend = function () {
                    var base64data = reader.result;

                    db.add(
                      `blobs.${blobId}`,
                      { data: base64data },
                      () => {
                        console.log("Record BLOB Added Succesfully");
                      },
                      (e) => {
                        alert(
                          "Error Adding Record: Check your log for more info."
                        );
                        console.log("Error Adding Record", e);
                      }
                    );
                  };
                })
                .catch((e) => {
                  console.log("Error:", e);
                });
            }

            /////////Update Viewed in firebase////////////
            if (!invoice.viewed) {
              db.update(
                `invoices.${invoice.id}`,
                { viewed: true, viewedAT: new Date() },
                () => {
                  const msg = `${invoice.accountName} has opened invoice ${invoice.invoiceNum}`;
                  sendNotifications("invoice_opened", msg);
                },
                (e) => {
                  console.log("Error Update Viewed in firebase", e);
                }
              );
            }
          }
        },
        (error) => {
          console.log("Error getting documents:", error);
        },
        {
          query: `invoiceLink == ${id}`,
        }
      );
    });
  };

  useEffect(() => {
    getCompanySettings().then((companySettings) => {
      getInvoice().then((invoice) => {
        getClientAccount(invoice.accountNumber).then((account) => {
          var invoice_ = {
            ...invoice,
            client: account,
            company: companySettings,
          };
          console.log("eeeeeeeeeeeee", invoice_);
          setinvoice(invoice_);
        });
      });
    });

    return () => {};
  }, []);

  const indicator_size = 70;

  return (
    <div style={{ height: "100vh" }}>
      {invoice ? (
        <PDFViewer width="100%" height="100%" className="app">
          <PDFInvoice
            invoice={invoice}
            setshowLoadingIndicator={setshowLoadingIndicator}
          />
        </PDFViewer>
      ) : null}
      {showLoadingIndicator ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 90,
          }}
        >
          <div>
            <ReactLoading
              type={"spin"}
              color={"green"}
              height={indicator_size}
              width={indicator_size}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
