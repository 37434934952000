import React, { useState, useContext, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function INetAlert({ alert }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!alert) setOpen(false);
    else setOpen(true);

    console.log("track", alert);
    return () => {};
  }, [alert]);

  return alert ? (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={alert}
      >
        <div style={{ padding: "30px 30px" }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: "700",
              lineHeight: 1.1,
              marginBottom: 20,
            }}
            id="alert-dialog-title"
          >
            {alert.title ? alert.title : "No Title : add alert.title field"}
          </div>
          <div style={{ fontSize: 15, lineHeight: 1 }}>
            {alert.subTitle ? alert.subTitle : ""}
          </div>
        </div>

        <DialogActions>
          <Button onClick={alert.onNo} color="primary">
            {alert.onNoTitle ? alert.onNoTitle : "No"}
          </Button>
          <Button onClick={alert.onYes} color="primary" autoFocus>
            {alert.onYesTitle ? alert.onYesTitle : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
}
