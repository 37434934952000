import React, { useState, useContext, useRef, useEffect } from "react";

import SlickSlider from "react-slick";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function Slider({
  settings,
  updateCount,
  setupdateCount,
  slideIndex,
  setslideIndex,
  slider,
  pagination,
}) {

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div className="banner-slider-buttons" style={{}}>
        <div
          className="banner-slider-button TouchableOpacity"
          onClick={(e) => {
            slider.current.slickGoTo(slideIndex - 1);
          }}
          style={{}}
        >
          <ArrowBackIosIcon
            fontSize="small"
            className="banner-slider-button-icon"
            style={{
              position: "relative",
              left: 5,
              color: "white",
            }}
          />
        </div>
        <div
          className="banner-slider-button TouchableOpacity"
          onClick={(e) => {
            slider.current.slickGoTo(slideIndex + 1);
          }}
          style={{}}
        >
          <ArrowForwardIosIcon
            fontSize="small"
            className="banner-slider-button-icon"
            style={{}}
          />
        </div>
      </div>

      <div className="banner-picture-label-position">
        <div className="banner-picture-label-bg">
          <div className="banner-picture-label">
            {pagination[slideIndex].name}
          </div>
        </div>
      </div>
      <div className="banner-heading-postion">
        <div className="banner-heading-title">
          Home And Office <br /> Automation Solutions
        </div>
      </div>
      <div className="banner-slider-pagination-dots">
        {pagination.map((item, i) => {
          return (
            <div
              key={i.toString()}
              className="banner-slider-pagination-dots-container"
            >
              <div
                className="banner-slider-pagination-plain-dots"
                style={{
                  height: 10,
                  width: 10,
                  backgroundColor: "#F2F2F2",
                  opacity: slideIndex != i ? 0.2 : 1,
                  borderRadius: 50,
                }}
              ></div>
              <img
                className="banner-slider-pagination-dots-size"
                onClick={item.onClick}
                src={item.src}
                style={{
                  opacity: slideIndex != i ? 0.3 : 1,
                }}
              ></img>
            </div>
          );
        })}
      </div>

      <SlickSlider
        ref={(ref) => {
          slider.current = ref;
        }}
        {...settings}
      >
        {pagination.map((item, i) => {
          return (
            <div key={i.toString()}>
              <img className="banner-image" src={item.src} />
            </div>
          );
        })}
      </SlickSlider>
    </div>
  );
}
