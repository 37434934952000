import React from "react";

const Policy = () => {
  return (
    <>
      <header
        style={{
          textAlign: "center",
          marginBottom: "50px",
          fontFamily: "Poppins",
        }}
      >
        <h1>Ts & Cs</h1>
      </header>

      <div style={{ fontFamily: "Poppins", fontSize: 15 }}>
        <ol>
          <li>
            <strong>Detailed description of goods and/or services</strong>
            <p>
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              is a business in the Telecommunications industry that provides
              Home & Office automation equipment and services.
            </p>
          </li>

          <li>
            <strong>Delivery policy</strong>
            <p>
              Subject to availability and receipt of payment, requests will be
              processed within 2 days and delivery confirmed by way of booking
              number which will be used for installation or courier services
              where applicable at costs stipulated at checkout.
            </p>
          </li>

          <li>
            <strong>Return and Refunds policy</strong>
            <p>
              The provision of goods and services by{" "}
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              is subject to availability. In cases of unavailability,{" "}
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              will refund the client in full within 30 days. Cancellation of
              orders by the client will attract a 15% administration fee.
            </p>
          </li>

          <li>
            <strong>Customer Privacy policy</strong>
            <p>
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              shall take all reasonable steps to protect the personal
              information of users. For the purpose of this clause, "personal
              information" shall be defined as detailed in the Promotion of
              Access to Information Act 2 of 2000 (PAIA). The PAIA may be
              downloaded from:
              http://www.lawsofsouthafrica.up.ac.za/index.php/current-legislation
            </p>
          </li>

          <li>
            <strong>Payment options accepted</strong>
            <p>
              Payment may be made via Visa, MasterCard, Diners or American
              Express Cards or by bank transfer into the{" "}
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              bank account, the details of which will be provided on request.
            </p>
          </li>

          <li>
            <strong>Card acquiring and security</strong>
            <p>
              Card transactions will be acquired for{" "}
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              via PayGate (Pty) Ltd who are the approved payment gateway for all
              South African Acquiring Banks. DPO PayGate uses the strictest form
              of encryption, namely Secure Socket Layer 3 (SSL3) and no Card
              details are stored on the website. Users may go to
              www.paygate.co.za to view their security certificate and security
              policy
            </p>
          </li>

          <li>
            <strong>Customer details separate from card details</strong>
            <p>
              Customer details will be stored by{" "}
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              separately from card details which are entered by the client on
              DPO PayGate’s secure site. For more detail on DPO PayGate refer to
              www.paygate.co.za.
            </p>
          </li>

          <li>
            <strong>Merchant Outlet country and transaction currency</strong>
            <p>
              The merchant outlet country at the time of presenting payment
              options to the cardholder is South Africa. Transaction currency is
              South African Rand (ZAR).
            </p>
          </li>

          <li>
            <strong>Responsibility</strong>
            <p>
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              takes responsibility for all aspects relating to the transaction
              including sale of goods and services sold on this website,
              customer service and support, dispute resolution and delivery of
              goods
            </p>
          </li>

          <li>
            <strong>Country of domicile</strong>
            <p>
              This website is governed by the laws of South Africa and{" "}
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              chooses as its domicilium citandi et executandi for all purposes
              under this agreement, whether in respect of court process, notice,
              or other documents or communication of whatsoever nature 2 FIR
              STREET, OBSERVATORY, CAPE TOWN, 7925 .
            </p>
          </li>

          <li>
            <strong>Variation</strong>
            <p>
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              may, in its sole discretion, change this agreement or any part
              thereof at any time without notice.
            </p>
          </li>

          <li>
            <strong>Company information</strong>
            <p>
              This website is run by Private company based in South Africa
              trading as{" "}
              <b>
                {" "}
                I NET TELECOMS <small> (PTY) LTD</small>
              </b>{" "}
              and with registration number 2019/562715/07 and a Director.
            </p>
          </li>

          <li>
            <strong>VAT</strong>
            <p>
             VAT included on all products and services.
            </p>
          </li>

          <li>
            <strong>
              I NET TELECOMS <small>(PTY) LTD</small> contact details{" "}
            </strong>
            <br />
            <p>
              <h6>
                Company Physical Address: 2 FIR STREET, BLACK RIVER PARK, BLOCK
                B, OBSERVATORY, CAPE TOWN , 7925
              </h6>
              <h6> Email: accounts@i-nettelecoms.co.za</h6>
              <h6>Telephone: 0212055802</h6>
            </p>
          </li>
        </ol>
      </div>
    </>
  );
};

export default Policy;
