import Heading2 from "components/Heading2";
import React from "react";
import image1 from "assets/images/values/1.jpg";
import image2 from "assets/images/values/2.jpg";
import image3 from "assets/images/values/3.jpg";
import image4 from "assets/images/values/4.jpg";
import { Col, Row } from "react-bootstrap";

export default function Welcome() {
  const data = [
    { name: "24/7 Support", image: image1 },
    { name: "Quality Work", image: image2 },
    { name: "No Contracts", image: image3 },
    { name: "Reliable Team", image: image4 },
  ];

  return (
    <div className="welcome" style={{}}>
      <Heading2 title="Welcome to the future of home & office technology" />
      <Row
        className="values"
        style={{
          margin: "40px 60px",
          padding: "0px 5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {data.map((item, i) => {
          const card_size = 220;
          const mini_height = 40;
          return (
            <Col key={i.toString()}>
              <div
                style={{
                  height: card_size,
                  width: card_size,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  color: "white",
                  overflow: "hidden",
                  flexDirection: "column",
                  backgroundColor: "rgba( 38, 73, 74, 1)",
                }}
              >
                <img
                  src={item.image}
                  style={{
                    flex: 1,

                    height: 160,
                    //     width: '100%',
                    //  position: 'absolute',
                  }}
                />
              </div>
              <div
                style={{
                  height: mini_height,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontFamily: "Roboto",
                  position: "relative",
                  backgroundColor: "rgba( 38, 73, 74, 0.9)",
                  width: card_size,
                  top: -mini_height,

                  color: "white",
                }}
              >
                {item.name}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
