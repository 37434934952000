import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Checkbox,
  Radio,
  Slider,
  Switch,
  TextField,
  Link,
  Tabs,
  Paper,
  Card,
  Dialog,
  Avatar,
  Divider,
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  ClickAwayListener,
  Alert,
  Tab,
  AppBar,
} from "@material-ui/core";
import SignIn from "./SignIn";
import CreateAccount from "./CreateAccount";
import INetAlert from "../INetAlert.js";
export default function AuthModal({ openAuthModal, setopenAuthModal }) {
  const closeModal = (value) => {
    setopenAuthModal(false);
  };
  const [tabValue, settabValue] = useState(0);
  const [cancelAuthAlert, setcancelAuthAlert] = useState("");

  return (
    <div>
      <INetAlert alert={cancelAuthAlert} />
      <Dialog
        style={{
          padding: 50,
          backgroundColor: "rgba( 0,0,0, 0.5)",
        }}
        onClose={() => {
          setcancelAuthAlert({
            title: "Sign in is not complete, Are you sure you want to cancel?",
            //  subTitle: "Sign in to get more feature on store.The",
            onYes: () => {
              setcancelAuthAlert("");
              setopenAuthModal(false);
            },
            onNo: () => {
              setcancelAuthAlert("");
            },
            onYesTitle: "Yes Cancel",
            onNoTitle: "No Continue",
          });
        }}
        open={openAuthModal}
        //    disableBackdropClick
        maxWidth="100vw"
      >
        <div
          style={{
            width: 530,
            color: "black",
          }}
        >
          <AppBar centered position="relative">
            <Tabs
              style={{ backgroundColor: "white" }}
              value={tabValue}
              onChange={(event, newValue) => {
                settabValue(newValue);
              }}
              aria-label="simple tabs example"
            >
              <Tab
                style={{
                  width: "100%",
                  backgroundColor: tabValue != 0 ? "#DEE1E6" : null,
                }}
                label="Sign In"
              />
              <Tab
                style={{
                  width: "100%",
                  backgroundColor: tabValue != 1 ? "#DEE1E6" : null,
                }}
                label="Create Account"
              />
            </Tabs>
          </AppBar>
          <div
            style={{
              padding: 50,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {tabValue == 0 ? (
              <SignIn closeModal={closeModal} />
            ) : (
              <CreateAccount closeModal={closeModal} />
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
