// ======= imported  all products images from images folder ========
import ahdCamera from "assets/images/services/1.jpg";
import ipCamera from "assets/images/services/1.jpg";
import Lte1 from "assets/images/services/1.jpg";
import Lte2 from "assets/images/services/1.jpg";
import Lte3 from "assets/images/services/1.jpg";
import Lte4 from "assets/images/services/1.jpg";
import Lte5 from "assets/images/services/1.jpg";
import Lte6 from "assets/images/services/1.jpg";
import Lte7 from "assets/images/services/1.jpg";
import Lte8 from "assets/images/services/1.jpg";
import fibre1 from "assets/images/services/1.jpg";
import fibre2 from "assets/images/services/1.jpg";
import fibre3 from "assets/images/services/1.jpg";
import fibre4 from "assets/images/services/1.jpg";
import fibre5 from "assets/images/services/1.jpg";
import fibre6 from "assets/images/services/1.jpg";
import internet from "assets/images/services/1.jpg";
import printer01 from "assets/images/services/1.jpg";
import printer02 from "assets/images/services/1.jpg";
import webDev from "assets/images/services/1.jpg";
import hosting from "assets/images/services/1.jpg";
import deskphone from "assets/images/services/1.jpg";
import codeless from "assets/images/services/1.jpg";
import switchBoard from "assets/images/services/1.jpg";
import apn from "assets/images/services/1.jpg";
import { VscDebugBreakpointLog } from "react-icons/vsc";

export const LTE = [
  {
    id: "101",
    title: "40GB LTE",
    price: 399,
    des: "20GB Anytime + 20GB Night | Includes Router Rental",
    des1: "Monthly billing",
  },
  {
    id: "102",
    title: "80GB LTE",
    price: 490,
    des: "40GB Anytime + 40GB Night | Includes Router Rental",
    des1: "Monthly billing",
  },
  {
    id: "103",
    title: "120GB LTE",
    price: 599,
    des: "60GB Anytime + 60GB Night | Includes Router Rental",
    des1: "Monthly billing",
  },
  {
    id: "104",
    title: "180GB LTE",
    price: 699,
    des: "90GB Anytime + 90GB Night | Includes Router Rental",
    des1: "Monthly billing",
  },
  // {
  //   id: "105",
  //   title: "BIRTHDAY SPECIAL - APRIL",
  //   price: 599,
  //   des: "10Mbps | FUP | Excluding Router Rental",
  // },
  {
    id: "106",
    title: "HOME UNCAPPED LTE",
    price: 999,
    des: "Unlimited data | FUP | 500GB | Includes Router",
    des1: "Monthly billing",
  },
  {
    id: "107",
    title: "BUSINESS UNCAPPED LTE",
    price: 1499,
    des: "Unlimited data | FUP | 1TB | Includes Router",
    des1: "Monthly billing",
  },
  {
    id: "108",
    title: "MOBILE ROUTER",
    price: 699,
    des: "Once off Purchase",
  },
  {
    id: "109",
    title: "MOBILE DATA",
    price: 199,
    des: "20GB | Mobile Data Sim Only",
    des1: "Monthly billing",
  },
  {
    id: "110",
    title: "WiFi MESH SYSTEM",
    price: 2999,
    des: "Once off Purchase | Full Mesh System | No More WiFi Dead Zones",
    des1: "Monthly billing",
  },
];

export const Fibre = [
  {
    id: "111",
    title: "25/25 Mbps FIBRE",
    price: 840,
    des: "Uncapped | Unshapped | No Fair Usage Policy",
    des1: "Monthly billing",
  },
  {
    id: "112",
    title: "50/50 Mbps FIBRE",
    price: 1165,
    des: "Uncapped | Unshapped | No Fair Usage Policy",
    des1: "Monthly billing",
  },
  {
    id: "113",
    title: "100/100 Mbps FIBRE",
    price: 1299,
    des: "Uncapped | Unshapped | No Fair Usage Policy",
    des1: "Monthly billing",
  },
  {
    id: "114",
    title: "200/200 Mbps FIBRE",
    price: 1490,
    des: "Uncapped | Unshapped | No Fair Usage Policy",
    des1: "Monthly billing",
  },
  {
    id: "115",
    title: "500/250 Mbps FIBRE",
    price: 1690,
    des: "Uncapped | Unshapped | No Fair Usage Policy",
    des1: "Monthly billing",
  },
  {
    id: "116",
    title: "1000/500 Mbps FIBRE",
    price: 2870,
    des: "Uncapped | Unshapped | No Fair Usage Policy",
    des1: "Monthly billing",
  },
  {
    id: "117",
    title: "WiFi MESH SYSTEM",
    price: 2999,
    des: "Once off Purchase | Full Mesh System | No More WiFi Dead Zones",
    des1: "Monthly billing",
  },
];

export const Telephones = [
  {
    id: "118",
    title: "BASIC",
    price: 180,
    imgUrl: deskphone,
    des1: "Suitable for home use or a 1 man small business.",
    des2: "1 Phone/Extension.",
    des3: "1 Line & Geographic Telephone Number.",
    des4: "Number Migration/Portability.",
  },
  {
    id: "119",
    title: "STANDARD",
    price: 650,
    imgUrl: codeless,
    des1: "Suitable for a small to medium sized business.",
    des2: "2-5 telephones/extensions Cloud Based PBX System.",
    des3: "Unlimited Lines & Multiple Geographic Telephone Numbers.",
    des4: "Number Migration/Portability.",
    des5: "Multiple Sites/Branches.",
  },
  {
    id: "120",
    title: "PREMIUM",
    price: 1250,
    imgUrl: switchBoard,
    des1: "Suitable for a Medium to Large sized business.",
    des2: "6-15 telephones/extensions Cloud Based PBX System.",
    des3: "Unlimited Lines & Unlimited Geographic Telephone Numbers.",
    des4: "Number Migration/Portability.",
    des5: "Multiple Sites/Branches.",
  },
  {
    id: "121",
    title: "CORPORATE",
    price: 3500,
    imgUrl: apn,
    des1: "Suitable for Large corporate businesses or Call Centers or Multiple Branches Businesses.",
    des2: "16+ telephones/extensions Cloud Based PBX System.",
    des3: "Unlimited Lines & Unlimited Geographic Telephone Numbers.",
    des4: "Number Migration/Portability.",
    des5: "Multiple Sites/Branches.",
    des6: "Client Personalized Login Portal to manage users.",
  },
];

export const Printers = [
  {
    id: "122",
    title: "A4 PRINTER",
    price: 0,
    imgUrl: printer01,
    des: "Black & White",
  },
  {
    id: "123",
    title: "A4 PRINTER",
    price: 0,
    imgUrl: printer02,
    des: "Color",
  },
  {
    id: "124",
    title: "A4/A3 PRINTER",
    price: 0,
    imgUrl: printer02,
    des: "Black & White",
  },
  {
    id: "125",
    title: "A4/A3 PRINTER",
    price: 0,
    imgUrl: printer02,
    des: "Color",
  },
  {
    id: "126",
    title: "RISO",
    price: 0,
    imgUrl: printer02,
    des: "",
  },
  {
    id: "127",
    title: "PLOTTER",
    price: 0,
    imgUrl: printer02,
    des: "",
  },
];

export const Camera = [
  {
    id: "128",
    title: "4CH AHD CCTV KIT",
    price: 9399,
    imgUrl: ahdCamera,
    des1: "1 x 4CH DVR",
    des2: "4 x Dome / Bullet Cameras",
    des3: "1 x 1TB HDD",
    des4: "1 x 100m cable",
    des5: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R1899 <input type="checkbox" name="Add Monitor" />{" "}
    //   </div>
    // ),
  },

  {
    id: "130",
    title: "8CH AHD CCTV KIT",
    price: 15599,
    imgUrl: ipCamera,
    des1: "1 x 8CH DVR",
    des2: "8 x Dome / Bullet Cameras",
    des3: "1 x 1TB HDD",
    des4: "1 x 200m cable",
    des5: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R2499 <input type="checkbox" name="Add Monitor" />{" "}
    //   </div>
    // ),
  },

  {
    id: "132",
    title: "16CH AHD CCTV KIT",
    price: 19899,
    imgUrl: ipCamera,
    des1: "1 x 16CH DVR",
    des2: "16 x Dome / Bullet Cameras",
    des3: "1 x 2TB HDD",
    des4: "1 x 500m cable",
    des5: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R3599 <input type="checkbox" name="Add Monitor" />{" "}
    //   </div>
    // ),
  },

  {
    id: "134",
    title: "32CH AHD CCTV KIT",
    price: 36450,
    imgUrl: ipCamera,
    des1: "1 x 32CH DVR",
    des2: "32 x Dome / Bullet Cameras",
    des3: "1 x 2TB HDD",
    des4: "1 x 1000m cable",
    des5: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R4299
    //     <input type="checkbox" name="Add Monitor" />{" "}
    //   </div>
    // ),
  },

  {
    id: "129",
    title: "4CH IP CCTV KIT",
    price: 16918.2,
    imgUrl: ipCamera,
    des1: "1 x 4CH NVR",
    des2: "4 x Dome / Bullet Cameras",
    des3: "1 x 1TB HDD",
    des4: "1 x 100m Cat6a cable",
    des5: "1 x 5 Port POE Switch",
    des6: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R1899 <input type="checkbox" name="Add Monitor" />{" "}
    //   </div>
    // ),
  },

  {
    id: "131",
    title: "8CH IP CCTV KIT",
    price: 21478.2,
    imgUrl: ipCamera,
    des1: "1 x 8CH NVR",
    des2: "8 x Dome / Bullet Cameras",
    des3: "1 x 1TB HDD",
    des4: "1 x 200m Cat6a cable",
    des5: "1 x 8 Port POE Switch",
    des6: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R2499 <input type="checkbox" name="Add Monitor" />{" "}
    //   </div>
    // ),
  },

  {
    id: "133",
    title: "16CH IP CCTV KIT",
    price: 29618.2,
    imgUrl: ipCamera,
    des1: "1 x 16CH DVR",
    des2: "16 x Dome / Bullet Cameras",
    des3: "1 x 2TB HDD",
    des4: "1 x 500m Cat6a cable",
    des5: "1 x 16 Port POE Switch",
    des6: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R3599 <input type="checkbox" name="Add Monitor" />{" "}
    //   </div>
    // ),
  },

  {
    id: "135",
    title: "32CH IP CCTV KIT",
    price: 63560,
    imgUrl: ipCamera,
    des1: "1 x 32CH DVR",
    des2: "32 x Dome / Bullet Cameras",
    des3: "1 x 2TB HDD",
    des4: "1 x 1000m Cat6a cable",
    des5: "1 x 32 Port POE Switch",
    des6: "1 x Full Installation",
    // des6: (
    //   <div style={{ color: "white", fontSize: "15px" }}>
    //     Add Monitor R4299{" "}
    //     <input type="checkbox" name="Add Monitor" onClick={""} />{" "}
    //   </div>
    // ),
  },
];

export const Monitors = [
  {
    id: "139",
    title: "4CH CCTV Monitor",
    price: 1899,
    imgUrl: webDev,
    des1: "Website Development",
  },
  {
    id: "140",
    title: "8CH CCTV Monitor",
    price: 2499,
    imgUrl: hosting,
    des1: "Website Development",
  },
  {
    id: "141",
    title: "16CH CCTV Monitor",
    price: 3599,
    imgUrl: hosting,
    des1: "Website Development",
  },
  {
    id: "142",
    title: "32CH CCTV Monitor",
    price: 4299,
    imgUrl: hosting,
    des1: "Website Development",
  },
];

export const WebApp = [
  {
    id: "136",
    title: "SMALL",
    price: 0,
    imgUrl: webDev,
    des1: "Website Development",
    des2: "App Development",
    des3: "Hosting",
    des4: "Domain",
    des5: "UI/UX Design",
  },
  {
    id: "137",
    title: "MEDIUM",
    price: 0,
    imgUrl: hosting,
    des1: "Website Development",
    des2: "Mobile App Development",
    des3: "Hosting",
    des4: "Domain",
    des5: "UI/UX Design",
  },
  {
    id: "138",
    title: "LARGE",
    price: 0,
    imgUrl: hosting,
    des1: "Website Development",
    des2: "Mobile App Development",
    des3: "Hosting",
    des4: "Domain",
    des5: "UI/UX Design",
  },
];
