import React, { useState, useContext, useRef, useEffect } from "react";
import Slider from "./components/Slider";

import image1 from "assets/images/banner/1.jpg";
import image2 from "assets/images/banner/2.jpg";
import image3 from "assets/images/banner/3.jpg";
import image4 from "assets/images/banner/4.jpg";
import image5 from "assets/images/banner/5.jpg";
import image6 from "assets/images/banner/6.jpg";

export default function RightSide() {
  const [slideIndex, setslideIndex] = useState(0);
  const [updateCount, setupdateCount] = useState(0);

  const slider = useRef();

  const settings = {
    autoplaySpeed: 5000,
    autoplay: true,
    pagination: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => setupdateCount(updateCount + 1),
    beforeChange: (current, next) => setslideIndex(next),
  };

  const pagination = [
    {
      name: "Telephones Systems",
      src: image1,
      onClick: () => {
        slider.current.slickGoTo(0);
      },
    },
    {
      name: "CCTV Cameras",
      src: image2,
      onClick: () => {
        slider.current.slickGoTo(1);
      },
    },
    {
      name: "Internet",
      src: image3,
      onClick: () => {
        slider.current.slickGoTo(2);
      },
    },
    {
      name: "Printers and Photocopiers",
      src: image4,
      onClick: () => {
        slider.current.slickGoTo(3);
      },
    },
    {
      name: "Email and Web Hosting",
      src: image5,
      onClick: () => {
        slider.current.slickGoTo(4);
      },
    },
    {
      name: "App Development",
      src: image6,
      onClick: () => {
        slider.current.slickGoTo(5);
      },
    },
  ];

  return (
    <div
      style={{
        textAlign: "left",

        height: "100%",
        width: "100%",
        backgroundColor: "orange",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <Slider
          updateCount={updateCount}
          setupdateCount={setupdateCount}
          slideIndex={slideIndex}
          setslideIndex={setslideIndex}
          slider={slider}
          settings={settings}
          pagination={pagination}
        />
      </div>
    </div>
  );
}
