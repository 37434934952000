import { PDFViewer } from "@react-pdf/renderer";
import { MainContext } from "contexts/MainContext";
import { db } from "plugins/firebase";
import { useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import PDFProposal from "./components/PDFProposal";
export default function ViewProposal({}) {
  const navigate = useNavigate();
  const { proposalLink } = useParams();
  const { sendNotifications } = useContext(MainContext);

  const [proposal, setproposal] = useState(null);
  const [showLoadingIndicator, setshowLoadingIndicator] = useState(true);

  useEffect(() => {
    db.get(
      `proposals`,
      (docs) => {
        if (docs.length == 0) {
          console.log("Proposal was not found found!");
          alert("Invalid Proposal Link.");
          navigate("/");
        } else {
          var proposal = docs[0];
          db.get(
            `settings.images.proposals`,
            (coverPageImage) => {
              if (coverPageImage.length != 0) {
                setproposal({
                  ...proposal,
                  coverImage: coverPageImage[0].image,
                });
              } else {
                setproposal(proposal);
              }
            },
            (e) => {
              alert("Error getting documents:");
              console.log("Error getting documents:", e);
            },
            {
              query: `idName == cover_page`,
            }
          );
          /////////Update Viewed in firebase////////////
          if (!proposal.viewed) {
            db.update(
              `proposals.${proposal.id}`,
              { viewed: true, viewedAT: new Date() },
              () => {
                const msg = `${proposal.client.name} has opened proposal ${proposal.id}`;
                sendNotifications("proposal_viewed", msg);
              },
              (e) => {
                console.log("Error Update Viewed in firebase", e);
              }
            );
          }
        }
      },
      (error) => {
        console.log("Error getting documents:", error);
      },
      {
        query: `proposalLink == ${proposalLink}`,
      }
    );

    return () => {};
  }, []);

  const indicator_size = 70;

  return (
    <div style={{ height: "100vh" }}>
      {proposal ? (
        <PDFViewer width="100%" height="100%" className="app">
          <PDFProposal
            proposal={proposal}
            setshowLoadingIndicator={setshowLoadingIndicator}
          />
        </PDFViewer>
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,

            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 90,
          }}
        >
          <div>
            <ReactLoading
              type={"spin"}
              color={"green"}
              height={indicator_size}
              width={indicator_size}
            />
          </div>
        </div>
      )}
    </div>
  );
}
