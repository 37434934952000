import Heading from "components/Heading";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  Button,
  Checkbox,
  Radio,
  Slider,
  Switch,
  TextField,
  Link,
  Tabs,
  Paper,
  Card,
  Dialog,
  Avatar,
  Divider,
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  ClickAwayListener,
  Alert,
} from "@material-ui/core";
import TemplateButton from "components/TemplateButton";
import image from "assets/images/contactus/1.jpg";
import image1 from "assets/images/contactus/1.png";
import image2 from "assets/images/contactus/2.png";
import image3 from "assets/images/contactus/3.png";

export default function ContactUs() {
  const contactInfo = [
    {
      name: "Location",
      image: image3,
      value: "Cape Town, Western Cape",
      value2: "South Africa",
      color: "blue",
    },
    {
      name: "Phone",
      image: image2,
      value: "(+27) 021 205 5802",
      value2: "+27 000 000 00",
      color: "green",
    },
    {
      name: "Email",
      image: image1,
      value: "info@i-nettelecoms.co.za",
      value2: "support@i-nettelecoms",
      color: "red",
    },
  ];

  const spacing = 20;
  return (
    <div className="contactus">
      <div className="contactus-heading-position">
        <Heading color="white" title="Contact Us" />
      </div>
      <div
        className="contactus-text-fields-wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "white",
        }}
      >
        <div
          className="contactus-text-fields-container"
          style={{
            display: "flex",
            width: "100%",
            marginTop: spacing,
            marginBottom: spacing,
          }}
        >
          <TextField
            variant="outlined"
            style={{
              color: "white",
            }}
            fullWidth
            label="Name"
          />
          <TextField
            className="contactus-text-fields"
            variant="outlined"
            inputProps={{ style: { fontFamily: "Poppins", color: "white" } }}
            fullWidth
            label="Company"
          />
        </div>
        <div
          className="contactus-text-fields-container"
          style={{
            display: "flex",
            width: "100%",
            marginBottom: spacing,
          }}
        >
          <TextField variant="outlined" fullWidth label="Email" />
          <TextField
            className="contactus-text-fields"
            variant="outlined"
            fullWidth
            label="Phone"
          />
        </div>

        <TextField
          variant="outlined"
          fullWidth
          label="Subject"
          style={{
            marginBottom: spacing,
          }}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Message"
          multiline
          rows={8}
          style={{
            marginBottom: spacing + 20,
          }}
        />

        <TemplateButton
          title="Send Message"
          style={{
            marginBottom: 60,
          }}
        />
      </div>
    </div>
  );
}
