///////////////////////////////////////////////////////////

import { number } from "prop-types";

export const calculateInvoiceTotal = (invoice) => {
  var subTotal = 0;
  invoice.items.map((item) => {
    var qty = item.qty;
    var price = item.unitPrice;
    var totalPrice = qty * price;
    subTotal = subTotal + totalPrice;
  });

  if (invoice.discountAmount) {
    var subTotal = subTotal - invoice.discountAmount;
  }
  var tax;
  if (invoice.isTaxExclusive) {
    tax = 0;
  } else {
    tax = subTotal * 0.15;
  }
  var invoiceTotal = (subTotal + tax).toFixed(2);

  return Number(invoiceTotal).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
};

export const calculateTax = (invoice) => {
  var total = 0;
  invoice.items.map((item) => {
    var qty = item.qty;
    var price = item.unitPrice;
    var totalPrice = qty * price;
    total = total + totalPrice;
  });

  if (invoice.discountAmount) {
    total = total - invoice.discountAmount;
  }
  var tax;
  if (invoice.isTaxExclusive) {
    tax = 0;
  } else {
    tax = (total * 0.15).toFixed(2);
  }

  return Number(tax).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
};

export const calculateSubtotalLessDiscount = (invoice) => {
  var total = 0;
  invoice.items.map((item) => {
    var qty = item.qty;
    var price = item.unitPrice;
    var totalPrice = qty * price;
    total = total + totalPrice;
  });

  var amt = (total - invoice.discountAmount).toFixed(2);

  return Number(amt).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
};

export const calculateSubTotal = (invoice) => {
  var total = 0;
  invoice.items.map((item) => {
    var qty = item.qty;
    var price = item.unitPrice;
    var totalPrice = qty * price;
    total = total + totalPrice;
  });

  total = total.toFixed(2);

  return Number(total).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
};
export const calculateLineTotal = (value1, value2) => {
  return (value1 * value2).toFixed(2).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
};
//////////////////////////////////////////////////////////////
