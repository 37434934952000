
import { toast } from 'react-toastify';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { danger, success, warning } from 'theme/theme';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
const options = {
    onOpen: props => {
        //console.log(props.foo)
    },
    onClose: props => {
        //  console.log(props.foo)
    },
    autoClose: 10000,
    // closeButton: FontAwesomeCloseButton,
    //type: toast.TYPE.INFO,
    hideProgressBar: false,
    pauseOnHover: true,
    //  transition: MyCustomTransition,
    closeOnClick: false,
    closeButton: false

};




const comp = ({ msg, color, icon }) => <div
    style={{
        color: 'black',
        //  backgroundColor: 'pink',
        width: 312,

        height: '100%',
        display: 'flex',

    }}
>
    <div
        style={{
            width: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 12,


        }}
    >
        {icon}

    </div>
    <div
        style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 15px 0px 15px',
            fontSize: 14,
            fontWeight: 'bold',

        }}
    >
        {msg}
    </div>
    <div
        style={{
            width: 15,
            height: 75,
            backgroundColor: color,
        }}
    >
    </div>
</div>

export const toastNormal = (msg) => {
    const appearance = {
        msg: msg,
        color: success,
        icon: <ErrorIcon style={{
            //    backgroundColor: primary_color,
            color: success,
            fontSize: 38,
        }} />,

    }
    toast(comp(appearance), options)
}

export const toastWarning = (msg) => {
    const appearance = {
        msg: msg,
        color: warning,
        icon: <WarningIcon style={{
            //    backgroundColor: primary_color,
            color: warning,
            fontSize: 38,
        }} />,

    }
    toast(comp(appearance), options)
}

export const toastError = (msg) => {



    const appearance = {
        msg: msg,
        color: danger,
        icon: <ErrorIcon style={{
            //    backgroundColor: primary_color,
            color: danger,
            fontSize: 38,
        }} />,

    }


    toast(comp(appearance, 'error'), options)
}