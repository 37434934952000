import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Checkbox,
  Radio,
  Slider,
  Switch,
  TextField,
  Link,
  Tabs,
  Paper,
  Card,
  Dialog,
  Avatar,
  Divider,
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  ClickAwayListener,
  Alert,
} from "@material-ui/core";

export default function CreateAccount({ closeModal }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          fontSize: 30,
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: 30,
        }}
      >
        Create A New Account
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 25,
        }}
      >
        <TextField
          label="User Name"
          variant="outlined"
          color="secondary"
          style={{ marginBottom: 20 }}
        />
        <TextField
          label="Email"
          variant="outlined"
          style={{ marginBottom: 20 }}
        />
        <TextField
          label="Password"
          variant="outlined"
          style={{ marginBottom: 20 }}
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          console.log("log");
        }}
        size="large"
      >
        Creat Account
      </Button>
    </div>
  );
}
