import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
import app_gallery from "assets/images/app_stores_logos/app_gallery.png";
import app_store from "assets/images/app_stores_logos/app_store.png";
import play_store from "assets/images/app_stores_logos/play_store.png";

export default function PageFooter({ adLink, border_color, border_width }) {
  const appStoresLogos = [
    {
      logo: app_store,
      link: "https://apps.apple.com/za/app/i-net-telecoms/id1564894890",
    },
    {
      logo: play_store,
      link: "https://play.google.com/store/apps/details?id=com.inettelecoms&gl=ZA",
    },
    {
      logo: app_gallery,
      link: "https://appgallery.huawei.com/app/C105302627",
    },
  ];
  return (
    <View
      fixed
      style={{
        height: adLink ? 150 : 35,
        bottom: 22,
        position: "absolute",
        padding: "0px 40px",
        width: "100vw",
      }}
    >
      {adLink ? (
        <>
          <View
            style={{
              backgroundColor: border_color,
              width: "100%",
              height: border_width,
            }}
          ></View>

          <View
            style={{
              width: "100%",
            }}
          >
            <Link src="https://i-nettelecoms.co.za">
              <Image
                style={{
                  margin: "5px 0px 5px 0px",
                  height: 105,
                  alignItems: "center",
                  justifyContent: "center",
                  width: 515,
                  backgroundColor: "blue",
                }}
                src={adLink}
              ></Image>
            </Link>
          </View>
        </>
      ) : null}

      <View
        style={{
          backgroundColor: border_color,
          width: "100%",
          height: border_width,
          marginBottom: 7,
        }}
      ></View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          Download Our Mobile App !
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {appStoresLogos.map((item, i) => {
            return (
              <Link key={i.toString()} src={item.link}>
                <Image
                  style={{
                    width: 85,
                    height: 23,
                    marginLeft: 10,
                    borderRadius: 5,
                  }}
                  source={item.logo}
                />
              </Link>
            );
          })}
        </View>
      </View>
      {/*  <Text
        fixed
        style={{
          fontSize: 8,
          textAlign: "center",
        }}
        render={({ pageNumber, totalPages }) => {
          return totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : null;
        }}
      ></Text> */}
      <Text
        style={{
          marginTop: 5,
          fontSize: 8,
          textAlign: "center",
        }}
      >
        Powerd by: NCODIA
      </Text>
    </View>
  );
}
