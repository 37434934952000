import React from "react";
import format from "date-fns/format";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
import { logo_2 } from "theme/images";
import { toMoney } from "plugins/valueConvetors";
import { text_grey } from "theme/colors";
import {
  calculateInvoiceTotal,
  calculateSubTotal,
  calculateSubtotalLessDiscount,
  calculateTax,
} from "plugins/invoiceCalculations";
import logo from "assets/images/logos/logo2.png";
import logo_icons from "assets/images/logos/logo_icons.jpg";
import Poppins from "assets/fonts/poppins/Poppins-Regular.ttf";

import Poppins_100 from "assets/fonts/poppins/Poppins-Thin.ttf";
import Poppins_200 from "assets/fonts/poppins/Poppins-ExtraLight.ttf";
import Poppins_300 from "assets/fonts/poppins/Poppins-Light.ttf";
import Poppins_400 from "assets/fonts/poppins/Poppins-Regular.ttf";
import Poppins_500 from "assets/fonts/poppins/Poppins-Medium.ttf";
import Poppins_600 from "assets/fonts/poppins/Poppins-SemiBold.ttf";
import Poppins_700 from "assets/fonts/poppins/Poppins-Bold.ttf";
import Poppins_800 from "assets/fonts/poppins/Poppins-ExtraBold.ttf";
import Poppins_900 from "assets/fonts/poppins/Poppins-Black.ttf";
import PageFooter from "./components/PageFooter";

export default function PDFStatement({ statement, setshowLoadingIndicator }) {
  // Font.register({ family: "Poppins", src: Poppins });

  Font.register({
    family: "Poppins",
    fonts: [
      { src: Poppins }, // font-style: normal, font-weight: normal
      {
        src: Poppins_100,
        fontWeight: 100,
      },
      {
        src: Poppins_200,
        fontWeight: 200,
      },
      {
        src: Poppins_300,
        fontWeight: 300,
      },
      {
        src: Poppins_400,
        fontWeight: 400,
      },
      {
        src: Poppins_500,
        fontWeight: 500,
      },
      {
        src: Poppins_600,
        fontWeight: 600,
      },
      {
        src: Poppins_700,
        fontWeight: 700,
      },
      {
        src: Poppins_800,
        fontWeight: 800,
      },
      {
        src: Poppins_900,
        fontWeight: 900,
      },
    ],
  });
  const columns = [
    {
      id: "date",
      title: "Date",
      width: "15%",
      backgroundColor: "red",
    },
    {
      id: "id",
      title: "Ref #",
      width: "15%",
      backgroundColor: "green",
    },
    {
      id: "description",
      title: "Description",
      width: "25%",
      backgroundColor: "red",
    },
    {
      id: "charges",
      title: "Debits",
      width: "15%",
      backgroundColor: "red",
    },
    {
      id: "payments",
      title: "Credits",
      width: "15%",
      backgroundColor: "green",
    },
    {
      id: "lineTotal",
      title: "Line Total",
      width: "15%",
      backgroundColor: "green",
    },
  ];
  const bankingDetails = [
    { title: "Bank Name", value: "Absa" },
    { title: "Account Name", value: "i NET TELECOMS (Pty) LTD" },
    { title: "Account Number", value: "4097804035" },
    { title: "Branch Code", value: "632005" },
    { title: "Branch Name", value: "Santyger" },
  ];
  const statementDetails = [
    { name: "statement #", value: "ST0000" },
    {
      name: "statement Date",
      value: statement.dateFormatted,
    },
    {
      name: "Balane Due",
      value: statement.accountBalance,
    },
  ];
  const accountSummary = [
    { name: "Start Date", value: statement.startDate },
    { name: "End Date", value: statement.endDate },
    { name: "Opening Balance", value: "R 0.00" },
    { name: "Total Balance Due", value: statement.accountBalance },
  ];
  console.log(statement);
  const clientContactInfo = [
    { name: "Phone", value: statement.contact.phone },
    { name: "Email", value: statement.contact.email },
    { name: "VAT", value: statement.contact.vatNum },
    { name: "ACC", value: statement.accountNumber },
  ];

  const timeLine = [
    { name: "120+ Days", value: statement.days.oneHundredAndTwentyDays },
    { name: "90 Days", value: statement.days.ninteyDays },
    { name: "60 Days", value: statement.days.sixteyDays },
    { name: "30 Days", value: statement.days.thirtyDays },
    { name: "Current", value: statement.days.current },
  ];
  const themeColor = "#4DB849";
  const border_color = "#DEE2E6";
  const border_width = 0.3;

  return (
    <Document
      onRender={() => {
        setshowLoadingIndicator(false);
      }}
      style={{}}
    >
      <Page
        size="A4"
        style={{
          paddingBottom: statement.adLink ? 175 : 60,
          fontFamily: "Poppins",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: 50,
            paddingRight: 50,
            // backgroundColor: "red",
          }}
        >
          {/* statement header ALL*/}
          <View
            fixed
            style={{
              marginTop: 50,
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* statement header left */}
            <View
              style={{
                width: "50%",
                alignItems: "flex-start",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <Image
                style={{
                  //  width: "50%",
                  left: -10,
                  height: 50,
                }}
                /*  src={{
                          uri:
                            "https://firebasestorage.googleapis.com/v0/b/i-net-app.appspot.com/o/assets%2Flogo2.png?alt=media&token=4df15f5a-3b4a-4f02-be51-ed0034fc13ed",
                        }} */
                src={logo_2}
              ></Image>
              <Image
                style={{
                  height: 30,
                  left: 30,
                }}
                src={logo_icons}
              ></Image>
            </View>
            {/* statement header Right */}
            <View
              style={{
                width: "50%",

                fontSize: 30,
                fontWeight: "bold",
              }}
            >
              <Text
                style={{
                  paddingBottom: 5,
                  color: themeColor,
                  textAlign: "right",
                  fontWeight: 600,
                  fontSize: 17,
                }}
              >
                Statement
              </Text>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",

                  margin: "10px 0px 10px 0px",
                  fontSize: 15,
                }}
              >
                {statementDetails.map((item, i) => {
                  return (
                    <View
                      key={i.toString()}
                      style={{
                        display: "flex",
                        flexDirection: "row",

                        justifyContent: "spaceBetween",
                        width: "75%",
                        marginBottom: 7,
                      }}
                    >
                      <View
                        style={{
                          width: 70,
                          left: 30,
                        }}
                      >
                        <Text
                          style={{
                            color: "#818181",
                            fontWeight: 500,
                            fontSize: 8.5,
                          }}
                        >
                          {item.name}
                        </Text>
                      </View>

                      <View
                        style={{
                          width: 115,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 600,
                            fontSize: 8.5,
                          }}
                        >
                          {item.value}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
          {/* statement Details ALL*/}
          <View
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 25,
            }}
          >
            {/* statement Details lEFT*/}

            <View
              style={{
                width: "49%",
                padding: "15px 0px 16px 7px",
                backgroundColor: "#F4F8F9",
                borderRadius: 5,
              }}
            >
              <Text style={styles.detailsHeading}>Billed to</Text>
              <View style={styles.detailsContainer}>
                <Text
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {statement.name}
                </Text>
                <Text style={{ fontSize: 9, fontWeight: 300 }}>
                  {statement.contact.address.street},{" "}
                  {statement.contact.address.area}
                </Text>
                <Text style={{ fontSize: 9, fontWeight: 300 }}>
                  {statement.contact.address.city},{" "}
                  {statement.contact.address.region}
                </Text>
                <View style={{ display: "flex", marginTop: 7 }}>
                  {clientContactInfo.map((item, i) => {
                    return (
                      <View
                        key={i.toString()}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 5,
                        }}
                      >
                        <View style={{}}>
                          <Text
                            style={{
                              display: "flex",
                              fontSize: 8.5,
                              width: 50,
                              fontWeight: 500,
                            }}
                          >
                            {item.name}
                          </Text>
                        </View>
                        <View style={{}}>
                          <Text
                            style={{
                              fontWeight: 300,

                              fontSize: 8.5,
                              width: "70%",
                            }}
                          >
                            {item.value}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
            {/* statement Details RIGHT*/}

            <View
              style={{
                backgroundColor: "#F4F8F9",
                borderRadius: 5,
                width: "49%",
                padding: "15px 0px 16px 7px",

                display: "flex",
                flexDirection: "column",
                marginLeft: 0,
              }}
            >
              <Text style={styles.detailsHeading}>Account Summary</Text>
              <View style={styles.detailsContainer}>
                <View
                  style={{
                    fontSize: 16,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 5,
                    }}
                  >
                    {accountSummary.map((item, i) => {
                      return (
                        <View
                          key={i.toString()}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 5,
                          }}
                        >
                          <View style={{ width: "70%" }}>
                            <Text
                              style={{
                                display: "flex",
                                fontSize: 8.5,

                                fontWeight: 500,
                              }}
                            >
                              {item.name}
                            </Text>
                          </View>
                          <View style={{ width: "30%" }}>
                            <Text
                              style={{
                                fontSize: 8.5,
                                fontWeight: 300,
                              }}
                            >
                              {item.value}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Table */}
          <View
            style={{
              marginBottom: 10,
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            {/*     Table header */}
            <View
              style={{
                width: "100%",
                backgroundColor: themeColor,
                display: "flex",
                flexDirection: "row",
                borderWidth: border_width,
                borderColor: border_color,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              {columns.map((column, i) => {
                return (
                  <View
                    style={{
                      width: column.width,
                      padding: 7,

                      borderRight: `${
                        i != columns.length - 1 ? border_width + "px" : 0
                      } solid ${border_color}`,
                      textAlign: column.id == "description" ? "left" : "center",
                    }}
                    key={i.toString()}
                  >
                    <Text style={{ fontSize: 9, color: "white" }}>
                      {column.title}
                    </Text>
                  </View>
                );
              })}
            </View>
            {/*     Table Body */}

            <View
              style={{
                width: "100%",
                backgroundColor: "#F4F8F9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {statement.transactions.map((statementItem, index) => {
                return (
                  <View
                    key={index.toString()}
                    wrap={false}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottomWidth:
                        index != statement.transactions.length - 1
                          ? border_width
                          : 0,
                      borderColor: "#C1C1C1",
                    }}
                  >
                    {columns.map((column, i) => {
                      const type = statementItem["type"];
                      const getDescription = () => {
                        if (type == "opening_balance") return "Opening Balance";
                        if (type == "invoice") return "Invoice";
                        if (type == "payment") return "Payment";
                        if (type == "creditNote") return "Credit Note";
                        return "";
                      };

                      const getAmount = (cellName) => {
                        if (type == "opening_balance") return "";
                        if (cellName == "charges" && type == "payment")
                          return "";
                        if (cellName == "payments" && type == "invoice")
                          return "";
                        if (cellName == "charges" && type == "creditNote")
                          return "";
                        return `R ${toMoney(statementItem["amount"])}`;
                      };
                      return (
                        <View
                          key={i.toString()}
                          style={{
                            flexDirection: "row",
                            width: column.width,
                            height: 30,
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                              padding: 5,
                              height: "100%",
                              // borderBottom: `${border_width}px solid ${border_color}`,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              paddingLeft: column.id == "description" ? 13 : 0,
                            }}
                            key={i.toString()}
                          >
                            <Text
                              style={{
                                fontSize: 9,
                                fontWeight: 400,
                                textAlign:
                                  column.id == "description"
                                    ? "left"
                                    : "center",
                              }}
                            >
                              {column.id == "date"
                                ? statementItem["dateFormatted"]
                                : null}
                              {column.id == "id"
                                ? statementItem[column.id]
                                : null}
                              {column.id == "description"
                                ? getDescription()
                                : null}
                              {column.id == "charges"
                                ? getAmount("charges")
                                : null}
                              {column.id == "payments"
                                ? getAmount("payments")
                                : null}
                              {column.id == "lineTotal"
                                ? `R ${toMoney(statementItem[column.id])}`
                                : null}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: border_width,
                              height: "100%",
                              backgroundColor: border_color,
                            }}
                          ></View>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>

            {/* table footer */}
            <View
              wrap={false}
              style={{
                width: "100%",
                backgroundColor: themeColor,
                display: "flex",
                flexDirection: "row",
                borderWidth: border_width,
                borderColor: border_color,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                padding: 7,
                marginBottom: 60,
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  color: "white",
                  marginRight: 60,
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                Account Balance
              </Text>
              <Text
                style={{
                  color: "white",
                  width: columns[columns.length - 1].width,
                  textAlign: "center",
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                {statement.accountBalance}
              </Text>
            </View>

            <View
              wrap={false}
              style={{
                backgroundColor: "#F4F8F9",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                borderRadius: 0,
              }}
            >
              <View
                style={{
                  width: border_width,
                  height: "100%",
                  backgroundColor: "black",
                }}
              />
              {timeLine.map((item, i) => {
                const textContainerStyles = {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                };
                const textStyles = {
                  textAlign: "right",
                  width: "100%",
                  fontSize: 10,
                  fontWeight: 500,
                  padding: "5px 15px",
                };
                return (
                  <View
                    key={i.toString()}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                    }}
                  >
                    <View
                      style={{
                        height: border_width,
                        width: "100%",
                        backgroundColor: "black",
                      }}
                    ></View>
                    <View
                      style={{
                        ...textContainerStyles,
                        borderBottom: `${border_width}px solid ${border_color}`,
                      }}
                    >
                      <Text style={textStyles}>{item.name}</Text>
                      <View
                        style={{
                          width: border_width,
                          height: "100%",
                          backgroundColor: "black",
                        }}
                      />
                    </View>
                    <View
                      style={{
                        height: border_width,
                        width: "100%",
                        backgroundColor: "black",
                      }}
                    ></View>
                    <View style={textContainerStyles}>
                      <Text style={textStyles}>{item.value}</Text>
                      <View
                        style={{
                          width: border_width,
                          height: "100%",
                          backgroundColor: "black",
                        }}
                      />
                    </View>
                    <View
                      style={{
                        height: border_width,
                        width: "100%",
                        backgroundColor: "black",
                      }}
                    ></View>
                  </View>
                );
              })}
            </View>
          </View>
        </View>

        {/* PAGE FOOTER */}
        <PageFooter adLink={statement.adLink} />
      </Page>
    </Document>
  );
}
const themeColor = "#4DB849";

const styles = StyleSheet.create({
  detailsHeading: {
    color: themeColor,
    fontSize: 10,
    fontWeight: 500,
    paddingLeft: 10,
    display: "flex",
    alignItems: "center",
  },

  detailsContainer: {
    paddingLeft: 10,
    marginTop: 10,
  },
});
