import TemplateButton from 'components/TemplateButton'
import React from 'react'

export default function Content({title, description, points}) {
    return (
<>
                       <div
                style={{
                    top: 5,
                    position: 'relative',

                            height: 50,
                            alignItems: 'flex-end',
                            //justifyContent: 'center',
                            fontSize: 30,
                            fontWeight: 'bold',
                            marginBottom: 25,

                            display: 'flex',

                        }}
                    >{title}</div>
                    <div
                        style={{
                            marginBottom: 20,
                            fontSize: 15,
                            fontWeight: '200',

                        }}
                    >
                        {description}
                    </div>
                    {
        points ? <ul
            style={{
                marginTop: 15,
                marginBottom: 40,
                paddingLeft: 20,
                fontWeight: 'bold',
                fontFamily: 'sans-serif',

            }}
        >
            {points.map((item, i) => {
                return (
                    <li key={i.toString()}

                    >
                        {item.description}
                    </li>
                )

            })}

        </ul> : null
    }


    <TemplateButton title='Request A Quote'
    />
</>

  
    )
}
