import Heading2 from "components/Heading2";
import React from "react";
import Slider from "./components/Slider";
import appstore from "assets/images/appstore.png";
import playstore from "assets/images/playstore2.png";
import image from "assets/images/screenshots/1.jpg";
import huawei from "assets/images/huawei.png";

export default function INetApp() {
  return (
    <div className="i-net-app-container" style={{}}>
      <Heading2
        title="Download our App"
        description="Experience our services in a different way with our mobile app, Which allows you to control your services and manage your subcriptions. The app has differnt core features that allow you to manage your subscription accounts with ease."
      />

      <div className="i-net-app" style={{}}>
        <div className="i-net-app-left-side">
          <div
            className="i-net-app-left-side2"
            style={
              {
                //  backgroundBlendMode: 'darken',
              }
            }
          >
            <div className="i-net-app-left-side-title" style={{}}>
              App Preview
            </div>

            <Slider />
          </div>
        </div>

        <div className="i-net-app-right-side" style={{}}>
          <div
            style={{
              padding: 30,
            }}
          >
            <div className="i-net-app-right-side-title" style={{}}>
              Top Features
            </div>
            <ul
              style={{
                fontSize: 20,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <li>Order New Services</li>
              <li>Upgrade Existing Services</li>
              <li>View Active Services</li>
              <li>Make Calls in App</li>
              <li>Make Online Payments</li>
            </ul>
            <div
              style={{
                fontWeight: "700",
                fontSize: 20,
                marginBottom: 25,
                marginTop: 50,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Available On
            </div>
            <div className="i-net-app-right-side-store-logos" style={{}}>
              <img
                onClick={(e) => {
                  window.open(
                    "https://apps.apple.com/za/app/i-net-telecoms/id1564894890"
                  );
                }}
                className="i-net-app-right-side-store-appstore"
                style={{}}
                src={appstore}
              ></img>
              <img
                className="i-net-app-right-side-store-appstore"
                onClick={(e) => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.inettelecoms&gl=ZA"
                  );
                }}
                // style={{
                //   borderRadius:10, height: 60, width: "20%"
                // }}
                src={playstore}
              ></img>

              <img
                onClick={(e) => {
                  window.open(
                    "https://appgallery.huawei.com/search/I-Net%20Telecoms"
                  );
                }}
                className="i-net-app-right-side-store-appstore"
                style={{ height: 60, marginBottom: 3 }}
                src={huawei}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
