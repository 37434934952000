import React from "react";
import service1 from "assets/images/services/5.jpg";
import TemplateButton from "components/TemplateButton";
import Content from "./Content";

export default function Layout1({ title, image, points, description, number }) {
  return (
    <div
      style={{
        display: "flex",
        fontFamily: "Roboto",
        height: 400,
      }}
    >
      <div
        style={{
          width: "50%",
          marginLeft: 30,
          overflow: "hidden",
        }}
      >
        <img
          style={{
            //  height: "100%",
            width: "100%",
            minHeight: "100%",
          }}
          src={image}
        ></img>
      </div>

      <div
        style={{
          width: "50%",
          backgroundColor: "#245054",
          marginRight: 30,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 30,
            marginTop: 30,

            width: 50,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // fontWeight: 'bold',
            // color:'black',
            backgroundColor: "#60d252",
            fontSize: 13,
          }}
        >
          {number}
        </div>
        <div
          style={{
            padding: 30,
            paddingRight: 80,
          }}
        >
          <Content title={title} points={points} description={description} />
        </div>
      </div>
    </div>
  );
}
