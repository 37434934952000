import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Link,
  Font,
} from "@react-pdf/renderer";
export default function Table({ columns, data }) {
  const border_color = "#565656";
  const border_width = 0.3;
  console.log("kkkkkkkkkkkkkkk", data);
  if (!data || !columns) {
    alert("Table missing props");
    return <Text>{"Table missing props : data={[]} or columns ={[]}"}</Text>;
  }
  const themeColor = "#4DB849";
  return (
    <View style={{}}>
      <View
        style={{
          width: "100%",
          backgroundColor: themeColor,
          display: "flex",
          flexDirection: "row",
          borderWidth: border_width,
          borderColor: border_color,
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        }}
      >
        {columns.map((column, i) => {
          return (
            <View
              style={{
                flex: column.width ? null : 1,
                width: column.width ? column.width : null,
                padding: 7,
                borderRightWidth: border_width,
                borderColor: border_color,
                textAlign: column.textAlign,
              }}
              key={i.toString()}
            >
              <Text
                fontWeight={600}
                style={{ fontSize: 9, color: "white", ...column.HeaderStyle }}
              >
                {column.Header}
              </Text>
            </View>
          );
        })}
      </View>
      <View
        style={{
          width: "100%",
          backgroundColor: "#F4F8F9",
          display: "flex",
          flexDirection: "column",
          borderBottomRightRadius: 2,
          borderBottomLeftRadius: 2,
        }}
      >
        {data.map((item, index) => {
          return (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                borderBottomWidth: index != data.length - 1 ? border_width : 0,
                borderColor: "#C1C1C1",
              }}
              key={index.toString()}
            >
              {columns.map((column, i) => {
                //***************************Meant to avoid errors for accessing objects, buy code does not work because dates come as objects too */
                // if (hasError)
                //   return <Text key={i.toString()}>'Table Has Error'</Text>;

                // if (typeof dataItem[column.accessor] == "object") {
                //   alert(
                //     `ERROR IN TABLE: Supplied a object as a accessor for "${column.Header}". Please use a string as accessor.`
                //   );
                //   hasError = true;
                // }

                var value = () => {
                  if (column.Cell) {
                    return column.Cell(
                      {
                        value: item[column.accessor],
                        cell: { row: { original: item } },
                      },
                      index
                    );
                  }
                  console.log("kkkkkkkkkkkkkkk", item);
                  return item[column.accessor];
                };

                return (
                  <View
                    style={{
                      flex: column.width ? null : 1,
                      width: column.width ? column.width : null,
                      height: 30,

                      padding: 5,
                      borderRightWidth: border_width,
                      borderLeftWidth: i == 0 ? border_width : 0,

                      borderColor: border_color,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    key={i.toString()}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        textAlign: column.textAlign,
                        ...column.CellStyle,
                        ...item.style,
                      }}
                    >
                      {value()}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>

      {/* <View
              style={{
                flexDirection: "row",in will you will you and you and you
              }}
            >
         
            </View> */}
    </View>
  );
}
