//V-1

import { initializeApp } from "firebase/app";
import {
  doc,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  collection,
  getFirestore,
  Timestamp,
  getDocs,
  onSnapshot,
  where,
  query,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
/* import {
  API_KEY,
  REACT_APP_AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
} from "@env"; */
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { isEven } from "./helperFunctions";
import { isDev } from "app-config";
const firebaseDevConfig = {
  apiKey: "AIzaSyDI7yyXwYxA09ndqUKl2Mooimmxn0wMCXg",
  authDomain: "i-net-dev.firebaseapp.com",
  projectId: "i-net-dev",
  storageBucket: "i-net-dev.appspot.com",
  messagingSenderId: "839291654516",
  appId: "1:839291654516:web:4b7b1617f242543c589c21",
  measurementId: "G-752QMSSX16",
};

var firebaseConfig = {
  apiKey: "AIzaSyBmM1OLF3yI7wo70m-SduZ1zQiCBJvjJC8",
  authDomain: "i-net-app.firebaseapp.com",
  projectId: "i-net-app",
  storageBucket: "i-net-app.appspot.com",
  messagingSenderId: "377129510369",
  appId: "1:377129510369:web:b1d89120e693f58f4f9ea0",
  measurementId: "G-REGBH18EWB",
  /*   apiKey: API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID, */
};
// Initialize Firebase
var app;
try {
  app = initializeApp(isDev ? firebaseDevConfig : firebaseConfig);
} catch (error) {
  alert("App could not connect to database");
  console.log("App could not connect to database", error);
}
const getPath = (path, type) => {
  const dbPath = path.split(".");
  var pathIsDoc = isEven(dbPath.length);

  if (pathIsDoc) {
    return doc(getFirestore(), ...dbPath);
  } else {
    if (type == "get") return collection(getFirestore(), ...dbPath);
    return doc(collection(getFirestore(), ...dbPath));
  }
};

class Datatbase {
  constructor() {
    this.db = getFirestore();
    this.arrayUnion = arrayUnion;
    this.arrayRemove = arrayRemove;
    this.query = query;
  }
  init = () => {
    try {
      initializeApp(firebaseConfig);
    } catch (error) {
      alert("App could not connect to database");
      console.log("App could not connect to database", error);
    }
  };

  //////////////////////////////////

  async add(path, data, onSuccess, onError) {
    var docRef = getPath(path);

    await setDoc(
      docRef,
      {
        ...data,
        createdAt: Timestamp.fromDate(new Date()),
      },
      { merge: true }
    )
      .then(onSuccess)
      .catch(onError);
  }
  //////////////////////////////////
  async get(path, onSuccess, onError, options) {
    const addKeys = options && options.addKeys ? options.addKeys : undefined;
    const query = options && options.query ? options.query : undefined;

    var docRef = getPath(path, "get");
    var pathIsDoc = isEven(path.split(".").length);
    try {
      if (pathIsDoc) {
        //const doc = await getDoc(docRef);
        onSnapshot(
          docRef,
          (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              const id = doc.id;
              const extraKeys = addKeys ? addKeys : {};
              onSuccess({ id, ...data, ...extraKeys });
            } else {
              onSuccess({});
            }
          },
          onError
        );
      } else {
        if (query) {
          /* replace(/ /g, ""). */
          const splitQuery = query.split(" ");

          docRef = this.query(docRef, where(...splitQuery));
        }
        onSnapshot(
          docRef,
          ({ docs }) => {
            var data = docs.map((doc) => {
              const id = doc.id;
              const extraKeys = addKeys ? addKeys : {};
              return { id, ...doc.data(), ...extraKeys };
            });
            data.sort(function (a, b) {
              return b.id.localeCompare(a.id);
            });
            onSuccess(data);
          },
          onError
        );
      }
    } catch (e) {
      console.log("GET_RECORD_ERROR", e);
    }
  }
  //////////////////////////////////
  async update(path, data, onSuccess, onError) {
    var docRef = getPath(path);
    console.log(data);
    await updateDoc(docRef, {
      ...data,
      updatedAt: Timestamp.fromDate(new Date()),
    })
      .then(onSuccess)
      .catch(onError);
  }
  //////////////////////////////////
  async delete(path, onSuccess, onError) {
    var docRef = getPath(path);
    /*  if (!data) {
      alert("No data passed to delete doc function.");
      return;
    } */
    const dbPath = path.split(".");
    var docId = dbPath[dbPath.length - 1];

    await deleteDoc(docRef).then(onSuccess).catch(onError);
    /*   db.add(`trash.${docId}`, {
      ...data,
      deletedAt: Timestamp.fromDate(new Date()),
    })
      ; */
  }
  //////////////////////////////////
}
class Auth {
  constructor() {
    this.auth = getAuth(app);
    this.onAuthStateChanged_firebase = onAuthStateChanged;
    this.signOut_firebase = signOut;
    this.createUserWithEmailAndPassword_firebase =
      createUserWithEmailAndPassword;
    this.signInWithEmailAndPassword_firebase = signInWithEmailAndPassword;
  }
  //////////////////////////////////
  currentUser = () => getAuth(app).currentUser;
  //////////////////////////////////
  onAuthChanged = (onSuccess, onError) => {
    this.onAuthStateChanged_firebase(this.auth, (user) => {
      onSuccess(user);
    });
  };
  //////////////////////////////////
  signOut = async (onSuccess, onError) => {
    await this.signOut_firebase(this.auth).then(onSuccess).catch(onError);
  };
  //////////////////////////////////
  createUserWithEmailAndPassword = async (
    email,
    password,
    onSuccess,
    onError
  ) => {
    this.createUserWithEmailAndPassword_firebase(this.auth, email, password)
      .then(onSuccess)
      .catch(onError);
  };
  //////////////////////////////////
  signInWithEmailAndPassword = async (email, password, onSuccess, onError) => {
    this.signInWithEmailAndPassword_firebase(this.auth, email, password)
      .then(onSuccess)
      .catch(onError);
  };
}
class Storage {
  constructor() {
    this.storage = getStorage(app);
  }

  //////////////////////////////////
  uploadFile = (file, path, onSnapShot, onSuccess, onError) => {
    const storageRef = ref(this.storage, path);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          snapshot.bytesTransferred / snapshot.totalBytes
        );
        onSnapShot(progress);
      },
      onError,
      () => {
        getDownloadURL(storageRef).then((url) => {
          onSuccess(url);
        });
      }
    );
  };
  //////////////////////////////////
  deleteFile = (fileUrl, onSuccess, onError) => {
    const storageRef = ref(this.storage, fileUrl);
    deleteObject(storageRef).then(onSuccess).catch(onError);
  };
  //////////////////////////////////
}
export const db = new Datatbase();
export const auth = new Auth();
export const storage = new Storage();
