import { colors } from "@material-ui/core";
import { amber, blue, green } from "@material-ui/core/colors";
import { primary_color } from "theme/theme";

const white = "purple";
const black = "#000000";

export default {
  black,
  white,
  //type: "light",
  primary: {
    //  contrastText: "red",
    //  dark: colors.red[900],
    main: colors.green[400],
    //light: colors.red[400],
  },
  secondary: {
    //contrastText: "pink",
    //  contrastText: white,
    //  dark: colors.red[900],
    main: colors.blue[400],
    //light: colors.red[400],
  },
  error: {
    contrastText: "purple",
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: "##24AAE8",
    secondary: "#FFC43D",
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: "pink",
    paper: "white",
  },

  divider: colors.grey[200],
};
