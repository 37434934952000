import React from "react";
import { useCart } from "react-use-cart";
import { AiFillHeart } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductCard = (props) => {
  const { addItem } = useCart();

  const productStyles = {
    color: "#bab8b8",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "5px",
    fontSize: "14px",
    marginTop: "10px",
  };
  const { title, imgUrl, price, des, des1, des2, des3, des4, des5, des6 } =
    props.item;

  const notify = () =>
    toast.info("Product added to cart!", { autoClose: 1000 });

  return (
    <div className="single_product_7">
      <div className="product_img_7">
        <div
          style={{
            marginBottom: 10,
            textAlign: "center",
            color: "#ffffff",
            paddingTop: 15,
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {title}
        </div>
        <hr style={{ color: "white" }} />
        {/* <img
          src={imgUrl}
          alt=""
          className=""
          style={{
            height: "200px",
            borderRadius: "15%",
            width: "100%",
            border: "0.4rem solid #26494A",
            margin: "0 auto 1.5rem",
            background: "transparent",
          }}
        /> */}
      </div>

      <div className="product_content_7">
        <div>
          <div style={productStyles}>{des}</div>
          <div style={productStyles}>{des1}</div>
          <div style={productStyles}>{des2}</div>

          <div style={productStyles}>{des3}</div>

          <div style={productStyles}>{des4}</div>

          <div style={productStyles}>{des5}</div>

          <div style={productStyles}>{des6}</div>
        </div>

        <hr style={{ color: "white" }} />

        <div className="d-flex align-items-center justify-content-between">
          <span className="price_7">
            Price: R<span>{price}</span>
          </span>
          {/* <div
            style={{
              marginLeft: "10px",
              // display: "grid",
              // gap: "1px",
              // gridTemplateColumns: "50px",
              color: "#fff",
            }}
          >
            <AiFillHeart
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#fff",
                marginLeft: "5px",
              }}
            />
            <small
              style={{
                fontSize: "11px",
                fontFamily: "Poppins",
                color: "#fff",
              }}
            >
              Add to list
            </small>
            <MdAddShoppingCart
              onClick={() => addItem(props.item)}
              style={{ fontSize: "22px", color: "#4CB848" }}
            />
          </div> */}
        </div>
        <div onClick={notify} style={{ display: "grid", marginTop: "10px" }}>
          <button
            className="btn "
            message="Product added to cart!"
            onClick={() => addItem(props.item)}
            important
            style={{ backgroundColor: "#4CB848", color: "#fff" }}
          >
            Add To Cart
          </button>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
